import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userRole: '',
  userEmail: '',
}

const useSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {
    setUserRole: (state, action) => {
      state.userRole = action.payload // Set the user role from the action payload
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload // Set the userEmail from the action payload
    },
  },
})

export const { setUserRole, setUserEmail } = useSlice.actions
export default useSlice.reducer
