import * as React from 'react'
import { Snackbar } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: 'linear-gradient(to bottom right, #DA4B48, #EB9554)',
  },
}))

const SnackbarMessage = ({ open, message }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false)

  React.useEffect(() => {
    if (message) {
      setIsSnackbarOpen(true)
    }
  }, [message])

  const classes = useStyles()

  return (
    <>
      <Snackbar
        ContentProps={{
          'aria-describedby': 'message-id',
          className: classes.snackbarStyleViaContentProps,
        }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        message={message}
        onClose={() => setIsSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </>
  )
}

export default SnackbarMessage
