import ToggleButton from 'src/components/common/ToggleButton/ToggleButton'
import { IDENTIFIER_TYPES } from 'src/utils/constants'
import { TextField } from '@mui/material'
import CustomDatePicker from 'src/components/common/CustomDatePicker'
import CustomDateTimePicker from "src/components/common/CustomDateTimePicker";
import React from "react";

const DynamicFormControl = ({
  id,
  name,
  label,
  slotProps,
  onChange,
  value,
  sx,
  error,
  errorText,
  placeholder,
  type,
  className,
  key,
}) => {
  const handleChange = (updatedValue) => {
    onChange({
      target: {
        name,
        value: updatedValue,
      },
    })
  }

  const getAnswerControl = () => {
    switch (type) {
      case IDENTIFIER_TYPES.BOOLEAN:
        return (
          <div>
            <span>{label}</span>
            <ToggleButton
              id="ToggleButton"
              onClick={(toggledValue) => handleChange(toggledValue)}
              checked={value}
            />
          </div>
        )
      case IDENTIFIER_TYPES.NUMBER:
        return (
          <div>
            <TextField
              id={id}
              name={name}
              label={label}
              placeholder={placeholder}
              value={value}
              type="text"
              onChange={(event) => {
                event.target.value = event.target.value
                    .replace(/[^0-9.]/g, '') // Remove non-numeric characters and extra decimal points
                    .replace(/^(\d*\.)(.*)\./g, '$1$2');

                const longMaxValue = Number.MAX_SAFE_INTEGER.toString();
                const inputValue = event.target.value;
                if (inputValue && (parseInt(inputValue) > parseInt(longMaxValue))) {
                  // If the input value exceeds the maximum, set it to the maximum
                  event.target.value = longMaxValue;
                }
                onChange(event)
              }}
              slotProps={{
                ...slotProps,
              }}
              sx={{
                ...sx,
              }}
              error={error}
              helperText={errorText}
              fullWidth
            />
          </div>
        )
      case IDENTIFIER_TYPES.DATE:
        return (
          <CustomDatePicker
            id={id}
            name={name}
            placeholder={placeholder}
            error={error}
            onChange={onChange}
            className={className}
            value={value}
            helperText={errorText}
          />
        )
      case IDENTIFIER_TYPES.DATE_TIME:
        return (
        <CustomDateTimePicker
            id={id}
            name={name}
            placeholder={placeholder}
            label={label}
            onChange={onChange}
            value={value}
        />
        )
      default:
        return (
          <div>
            <TextField
              id={id}
              name={name}
              label={label}
              placeholder={placeholder}
              value={value}
              type="text"
              onChange={onChange}
              slotProps={{
                ...slotProps,
              }}
              sx={{
                ...sx,
              }}
              error={error}
              helperText={errorText}
              fullWidth
            />
          </div>
        )
    }
  }

  return <div key={key}>{getAnswerControl()}</div>
}

export default DynamicFormControl
