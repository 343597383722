import md5 from 'md5'
import dayjs from 'dayjs'

const getEncodedText = (text) => {
  return md5(btoa(text))
}

const getFormattedValue = (value) => {
  return value ?? '-'
}

const getFullName = (nameList) => {
  const filteredNames = nameList.filter((nameListItem) => nameListItem)
  return filteredNames.join(' ').trim()
}

const getUserFormObject = (data) => {
  return {
    userId: data.userId,
    userFirstName: data.firstName,
    userLastName: data.lastName,
    userMobile: data.phone,
    userDept: data.department,
    userDesignation: data.designation,
    userEmail: data.email,
    roleId: data.roleId[0],
    password: '',
    confirmPassword: '',
    activeIndicator: data.activeIndicator,
  }
}

const getDateTime = (fieldValue) => {
  if (fieldValue) {
    return dayjs(fieldValue)
  }
  return null
}

const getDateTimeFromEpoch = (date) => {
  const dateInSec = date / 1000
  return dayjs.unix(dateInSec).format('DD MMM YYYY HH:mm:ss')
}

const getDuration = (startTime, endTime) => {
  return (endTime - startTime) / 1000
}

export {
  getEncodedText,
  getFormattedValue,
  getFullName,
  getUserFormObject,
  getDateTime,
  getDateTimeFromEpoch,
  getDuration,
}
