import { reducerActions } from 'src/actions/actionTypes'

const initialState = {
  type: null,
  message: null,
}

const notificationReducer = (state = initialState, action) => {
  if (action.type === reducerActions.SHOW_NOTIFICATION) {
    return {
      ...state,
      type: action.payload.type,
      message: action.payload.message,
    }
  }
  return state
}
export default notificationReducer
