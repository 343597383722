import moment from 'moment'
import md5 from 'md5'

export const APP_MENU_BACKGROUND = '#1967B2'

export const emailValidation = (email) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  return !(!email || regex.test(email) === false)
}

export const isValidMobileNumber = (mobileNumber) => {
  const regex = /^[0-9]{10}$/
  return !(!mobileNumber || regex.test(mobileNumber) === false)
}

export const setDateFormat = (date) => {
  if (date != null && date !== '') {
    let dateWithOutString = date.replace(/"/gi, '')
    dateWithOutString = moment(dateWithOutString).format('DD-MMM-YYYY')
    return dateWithOutString
  } else {
    return '-'
  }
}

// Text -> Base64 -> MD5
export const encryptVal = async (strVal) => {
  try {
    // Convert String to Base64
    const base64Str = btoa(strVal)

    // Hash the Base64-encoded String with MD5
    const hashedStr = md5(base64Str)

    return hashedStr
  } catch (error) {
    console.error('Error processing in encrypting string:', error)
    throw error
  }
}

const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
}

const FORM_ERROR_MESSAGES = {
  PROCESS_ID_REQUIRED: 'Process Id is required',
  FROM_DATE_REQUIRED: 'Please enter a date time for From Date',
  TO_DATE_REQUIRED: 'Please enter a date time for To Date',
}

const IDENTIFIER_TYPES = {
  DATE: 'DATE',
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  NUMBER: 'NUMBER',
  DATE_TIME: 'DATE_TIME'
}

const IDENTIFIER_TYPE_LIST = [
  {
    label: 'String',
    type: IDENTIFIER_TYPES.STRING,
  },
  {
    label: 'Number',
    type: IDENTIFIER_TYPES.NUMBER,
  },
  {
    label: 'Boolean',
    type: IDENTIFIER_TYPES.BOOLEAN,
  },
  {
    label: 'Date',
    type: IDENTIFIER_TYPES.DATE,
  },
  {
    label: 'Date Time',
    type: IDENTIFIER_TYPES.DATE_TIME,
  },
]

const PROCESS_MONITOR_FILTER_TYPES = {
  DATE: 'DATE',
  IDENTIFIERS: 'IDENTIFIERS',
}

export {
  FORM_ERROR_MESSAGES,
  NOTIFICATION_TYPES,
  IDENTIFIER_TYPES,
  IDENTIFIER_TYPE_LIST,
  PROCESS_MONITOR_FILTER_TYPES,
}
