import './App.css'
import { routes as appRoutes } from './routes'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Notification from 'src/components/common/Notification'
import LoadingModal from 'src/components/common/LoadingModal'

function App() {
  const urlLast = window.location.href.split('/') //splits the url when found a "/"
  const temp = urlLast[urlLast.length - 1] // gives the last of address /dashboard/register gives register

  return (
    <>
      <Notification />
      <LoadingModal />
      <Router>
        <Routes>
          {appRoutes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Routes>
      </Router>
    </>
  )
}

export default App
