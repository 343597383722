import { reducerActions } from 'src/actions/actionTypes'

const initialState = {
  loading: false,
}

const loadingReducer = (state = initialState, action) => {
  if (action.type === reducerActions.UPDATE_LOADING) {
    return {
      ...state,
      loading: action.payload.loading,
    }
  }
  return state
}
export default loadingReducer
