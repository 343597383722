import { Alert, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Notification = () => {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
  const notificationData = useSelector((state) => state.notificationReducer)
  const handleSnackbarClose = () => {
    setIsSnackBarOpen(false)
  }

  useEffect(() => {
    if (notificationData?.message) {
      setIsSnackBarOpen(true)
    }
  }, [notificationData])

  return (
    <>
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert onClose={handleSnackbarClose} severity={notificationData?.type}>
          {notificationData?.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Notification
