import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { emailValidation, NOTIFICATION_TYPES } from '../utils/constants'
import SnackbarMessage from '../components/snackbarmessage'
import api from '../api'
import CareHealthBg from '../assets/CHI_Background.png'
import altlogo from '../assets/alt-logo.svg'
import { useDispatch } from 'react-redux'
import { setUserEmail, setUserRole } from '../userSlice'
import { getEncodedText } from '../utils/commonMethods'
import './login.css'
import { showNotificationMessage, updateLoadingState } from 'src/actions'

// Main component
const LoginPage = () => {
  return (
    <div className="main-page">
      <LoginForm />
    </div>
  )
}

// Login form component
const LoginForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleSignIn = async () => {
    dispatch(updateLoadingState(true))
    try {
      if (!emailValidation(email)) {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Provided Email ahandleSignInRespddress is invalid`,
          })
        )
        dispatch(updateLoadingState(false))
        return false
      }

      const encodedPassword = getEncodedText(password)
      const handleSignInResp = await api.loginApi(email, encodedPassword)
      if (handleSignInResp.status === 200) {
        dispatch(setUserEmail(handleSignInResp.data.email))
        dispatch(setUserRole(handleSignInResp.data.roles[0].authority))

        sessionStorage.clear()
        sessionStorage.setItem('userEmail', handleSignInResp?.data?.email)
        sessionStorage.setItem(
          'userRole',
          handleSignInResp?.data?.roles[0].authority
        )
        sessionStorage.setItem('userId', handleSignInResp?.data?.userId)
        sessionStorage.setItem('userToken', handleSignInResp?.data?.jwt)

        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.SUCCESS,
            message: `Login successful`,
          })
        )
        setTimeout(() => {
          // Navigate to the dashboard
          dispatch(updateLoadingState(false))
          navigate('/dashboard')
        }, 1000)
      } else {
        dispatch(updateLoadingState(false))
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Login failed`,
          })
        )
        return false
      }
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Login failed. Reason : ${error}`,
        })
      )
      return false
    }
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSignIn()
    }
  }

  return (
    <>
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={8}>
          <div className={'login-client-image-container'}>
            <img
              src={CareHealthBg}
              alt="CHI"
              className={'login-client-image'}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="login-form-container">
            <div>
              <img
                src={altlogo}
                alt="Client Logo"
                className={'login-client-image'}
              />
            </div>

            <TextField
              className="login-email-id"
              placeholder="Email address"
              fullWidth
              value={email}
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
            />

            <OutlinedInput
              className="login-password"
              placeholder="Password"
              label="Password"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyPress}
            />

            <div className={'login-actions-container'}>
              <span className="login-forgot-password">Forgot password?</span>

              <Button
                className="signin-button"
                variant="contained"
                color="primary"
                onClick={handleSignIn}
              >
                Sign In
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default LoginPage
