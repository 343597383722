import './index.css'
import {
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import Popover from '@mui/material/Popover'
import {
  getDuration,
  getDateTimeFromEpoch,
  getFormattedValue,
} from 'src/utils/commonMethods'
import DatePickerImage from '../../assets/DatePicker.svg'
import React, { useEffect, useState } from 'react'
import api from '../../api'
import dayjs from 'dayjs'
import { showNotificationMessage } from 'src/actions'
import {
  NOTIFICATION_TYPES,
  PROCESS_MONITOR_FILTER_TYPES,
} from 'src/utils/constants'
import { useDispatch } from 'react-redux'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import {
  getFilterIdentifierFormData,
  getProcessLogFilterForm,
} from 'src/services/formHelperService'
import DateFilterForm from 'src/components/ProcessMonitorItem/DateFilterForm'
import IdentifierFilterForm from 'src/components/ProcessMonitorItem/IdentifierFilterForm'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const ProcessMonitorItem = ({
  processKey,
  processData,
  onProcessClick,
  hideDateFilter,
  hideIdentifierFilter,
  processDetails,
  processList,
  dashboardLogKeys,
  isDashboard = false,
    selectedFromDateTime,
    selectedToDateTime
}) => {
  const dispatch = useDispatch()
  const [processName, setProcessName] = useState(processKey)
  const [processDataList, setProcessDataList] = useState([])

  const [popOverMetaData, setPopOverMetaData] = useState({
    elementRef: null,
    componentType: '',
  })

  const [processMenuElement, setProcessMenuElement] = useState(null)
  const isProcessMenuOpen = Boolean(processMenuElement)

  const [filterForm, setFilterForm] = useState({
    fromDateTime: null,
    toDateTime: null,
  })

  console.log({selectedFromDateTime, selectedToDateTime, filterForm})

  useEffect(() => {
    if (processData?.length) {
      setProcessDataList([...processData])
    }
    if (processDetails) {
      setFilterForm({
        ...getProcessLogFilterForm(processDetails.systemProcessIdentifiers),
      })
    }

      setFilterForm({
        ...filterForm,
        fromDateTime: selectedFromDateTime,
        toDateTime: selectedToDateTime
      })
  }, [])

  const handleFormChange = (event) => {
    setFilterForm({
      ...filterForm,
      [event.target.name]: event.target.value,
    })
  }

  const handlePopOverToggle = (event, type) => {
    setPopOverMetaData({
      elementRef: event?.currentTarget,
      componentType: type,
    })
  }

  const getPopOverComponent = () => {
    switch (popOverMetaData.componentType) {
      case PROCESS_MONITOR_FILTER_TYPES.DATE:
        return (
          <DateFilterForm
            filterForm={filterForm}
            handleChange={handleFormChange}
            handleSubmit={() => handleSubmitForm()}
          />
        )
      case PROCESS_MONITOR_FILTER_TYPES.IDENTIFIERS:
        return (
          <IdentifierFilterForm
            filterForm={filterForm}
            handleChange={handleFormChange}
            handleSubmit={() => handleSubmitForm()}
            identifierList={processDetails?.systemProcessIdentifiers}
          />
        )
      default:
        return <></>
    }
  }

  const handleSubmitForm = async (name = processName) => {
    try {
      const fromDateTime = filterForm.fromDateTime
        ? dayjs(filterForm.fromDateTime).valueOf()
        : null
      const toDateTime = filterForm.toDateTime
        ? dayjs(filterForm.toDateTime).valueOf()
        : null

      const { formIdentifierKeys, formIdentifierValues } =
        getFilterIdentifierFormData(
          processDetails?.systemProcessIdentifiers,
          filterForm
        )

      const requestBody = {
        fromDate: fromDateTime,
        toDate: toDateTime,
        keyNames: formIdentifierKeys,
        values: formIdentifierValues,
        dashboard: isDashboard,
      }

      const response = await api.queryLogs(name, requestBody)
      if (response.status === 200) {
        setProcessDataList(response.data)
        handlePopOverToggle(null, '')
      } else {
        setProcessDataList([])
      }
    } catch (error) {
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  const isPopOverOpen = Boolean(popOverMetaData.elementRef)

  const getProcessName = () => {
    return getAvailableProcessList()?.length ? (
      <div
        className="process-item-name-container"
        onClick={handleProcessMenuToggle}
      >
        <span>{processName}</span>
        <ArrowDropDownIcon />
      </div>
    ) : (
      <>{processName}</>
    )
  }

  const handleProcessMenuToggle = (event) => {
    setProcessMenuElement(event?.target)
  }

  const handleProcessChange = (name) => {
    setProcessName(() => name)
    handleProcessMenuToggle(null)
    handleSubmitForm(name)
  }

  const getAvailableProcessList = () => {
    return (
      processList?.filter(
        (processListItem) => !processListItem.dashboardIndicator
      ) ?? []
    )
  }

  const getStatus = (status) => {
    switch (status.toLowerCase()) {
      case 'success':
        return <span className="success-status">{status}</span>
      case 'failed':
        return <span className="failed-status">{status}</span>
      default:
        return <span className="">{status}</span>
    }
  }

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={processMenuElement}
        open={isProcessMenuOpen}
        onClose={() => handleProcessMenuToggle(null)}
      >
        {processKey !== processName ? (
          <MenuItem onClick={() => handleProcessChange(processKey)}>
            {processKey}
          </MenuItem>
        ) : null}
        {getAvailableProcessList()?.map((processListItem) => {
          return (
            <MenuItem
              key={`processName__${processListItem.processId}`}
              onClick={() => handleProcessChange(processListItem.processName)}
            >
              {processListItem.processName}
            </MenuItem>
          )
        })}
      </Menu>

      <Popover
        id="datePickerPopover"
        open={isPopOverOpen}
        anchorEl={popOverMetaData.elementRef}
        onClose={() => {
          handlePopOverToggle(null, '')
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="popover-form-container">{getPopOverComponent()}</div>
      </Popover>
      <div className={'process-item-container'}>
        <div className={'process-item-header '}>
          <div className="flex">
            <div className={'process-item-name'}>{getProcessName()}</div>
            {!hideDateFilter ? (
              <div
                className={'process-date-picker-container'}
                onClick={(event) => {
                  handlePopOverToggle(event, PROCESS_MONITOR_FILTER_TYPES.DATE)
                }}
              >
                <img
                  src={DatePickerImage}
                  alt="Date Picker"
                  height={15}
                  width={15}
                />
              </div>
            ) : null}
          </div>
          {!hideIdentifierFilter ? (
            <div className="process-item-filter-icon-container">
              <FilterAltRoundedIcon
                className="process-item-filter-icon"
                onClick={(event) => {
                  handlePopOverToggle(
                    event,
                    PROCESS_MONITOR_FILTER_TYPES.IDENTIFIERS
                  )
                }}
              />
            </div>
          ) : null}
        </div>
        <div>
          <TableContainer className="process-table">
            <Table>
              <TableHead className={'process-table-head'}>
                <TableRow className={'process-table-row'}>
                  <TableCell className={'process-table-cell'}>Key</TableCell>
                  <TableCell className={'process-table-cell'}>PIM Id</TableCell>
                  <TableCell className={'process-table-cell'}>
                    Trace Id
                  </TableCell>
                  <TableCell className={'process-table-cell'}>
                    Service Name
                  </TableCell>
                  <TableCell className={'process-table-cell'}>
                    Start Time
                  </TableCell>
                  <TableCell className={'process-table-cell'}>
                    Duration
                  </TableCell>
                  <TableCell className={'process-table-cell'}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {processDataList.length ? (
                  <>
                    {processDataList?.map((dataItem) => {
                      return (
                        <TableRow
                          className={'process-table-row'}
                          key={`tableRow_${dataItem.traceId}`}
                          onClick={() => {
                            onProcessClick(dataItem)
                          }}
                        >
                          <TableCell>
                            <span className={'process-table-cell'}>
                              {getFormattedValue(dataItem.key)}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className={'process-table-cell'}>
                              {getFormattedValue(dataItem.pimId)}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className={'process-table-cell'}>
                              {getFormattedValue(dataItem.traceId)}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className={'process-table-cell'}>
                              {getFormattedValue(dataItem.serviceName)}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className={'process-table-cell'}>
                              {getDateTimeFromEpoch(dataItem.processStartTime)}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className={'process-table-cell'}>
                              {getDuration(
                                dataItem.processStartTime,
                                dataItem.processEndTime
                              )}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className={'process-table-cell'}>
                              {getStatus(dataItem.status)}
                            </span>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </>
                ) : (
                  <TableRow className={'process-table-row'}>
                    <TableCell colSpan={7} className={'process-table-cell'}>
                      <span className="no-data-text">No Queries available</span>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  )
}

export default ProcessMonitorItem
