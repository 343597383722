import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

const TransferLists = ({
  leftListItems = [], // Ensure default values are arrays
  rightListItems = [],
  setTransferLists,
  leftHeading,
  rightHeading,
}) => {
  const [leftSelectedItems, setLeftSelectedItems] = useState({})
  const [rightSelectedItems, setRightSelectedItems] = useState({})
  const [searchLeft, setSearchLeft] = useState('')
  const [searchRight, setSearchRight] = useState('')

  const filterList = (list, filter) => {
    return list?.filter((item) =>
      item?.processName?.toLowerCase().includes(filter?.toLowerCase())
    )
  }

  const filteredLeftList = filterList(leftListItems, searchLeft)
  const filteredRightList = filterList(rightListItems, searchRight)

  useEffect(() => {
    setRightSelectedItems({})
    setLeftSelectedItems({})
  }, [leftListItems, rightListItems])

  const moveAll = (direction) => {
    if (direction === 'left') {
      setTransferLists({
        leftListItems: [...leftListItems, ...rightListItems],
        rightListItems: [],
      })
      setRightSelectedItems({})
    } else if (direction === 'right') {
      setTransferLists({
        leftListItems: [],
        rightListItems: [...leftListItems, ...rightListItems],
      })
      setLeftSelectedItems({})
    }
  }

  const transferItems = (direction) => {
    if (direction === 'left') {
      setTransferLists({
        leftListItems: [...leftListItems, ...Object.values(rightSelectedItems)],
        rightListItems: rightListItems.filter(
          (item) => !rightSelectedItems[item.processId]
        ),
      })
      setRightSelectedItems({})
    } else if (direction === 'right') {
      setTransferLists((prevLists) => ({
        leftListItems: (prevLists.leftListItems || []).filter(
          (item) => !leftSelectedItems[item.processId]
        ),
        rightListItems: [
          ...(prevLists.rightListItems || []),
          ...Object.values(leftSelectedItems),
        ],
      }))
      setTransferLists({
        rightListItems: [
          ...rightListItems,
          ...Object.values(leftSelectedItems),
        ],
        leftListItems: leftListItems.filter(
          (item) => !leftSelectedItems[item.processId]
        ),
      })
      setLeftSelectedItems({})
    }
  }

  return (
    <div className="transfer-lists">
      <div className="left-list-container">
        <div className="list-header">
          <span className="list-header-text">{leftHeading}</span>
          <TextField
            placeholder="Search"
            variant="outlined"
            fullWidth
            className="search-list"
            value={searchLeft}
            onChange={(e) => setSearchLeft(e.target.value)}
          />
        </div>
        <div className="list-items-container">
          {filteredLeftList?.map((item) => (
            <div
              key={item.processId}
              className={`list-item ${
                leftSelectedItems[item.processId] ? 'selected-item' : ''
              }`}
              onClick={() =>
                setLeftSelectedItems((prev) => {
                  const updatedSelection = { ...prev }
                  if (updatedSelection[item.processId]) {
                    delete updatedSelection[item.processId]
                  } else {
                    updatedSelection[item.processId] = item
                  }
                  return updatedSelection
                })
              }
            >
              {item?.processName}
            </div>
          ))}
        </div>
      </div>

      <div className="action-buttons">
        <Button
          variant="contained"
          color="primary"
          onClick={() => transferItems('right')}
          className="transfer-button"
        >
          &gt;
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => transferItems('left')}
          className="transfer-button"
        >
          &lt;
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => moveAll('right')}
          className="transfer-button move-all"
        >
          &gt;&gt;
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => moveAll('left')}
          className="transfer-button move-all"
        >
          &lt;&lt;
        </Button>
      </div>

      <div className="right-list-container">
        <div className="list-header">
          <span className="list-header-text">{rightHeading}</span>
          <TextField
            placeholder="Search"
            variant="outlined"
            fullWidth
            className="search-list"
            value={searchRight}
            onChange={(e) => setSearchRight(e.target.value)}
          />
        </div>
        <div className="list-items-container">
          {filteredRightList?.map((item) => (
            <div
              key={item.processId}
              className={`list-item ${
                rightSelectedItems[item.processId] ? 'selected-item' : ''
              }`}
              onClick={() =>
                setRightSelectedItems((prev) => {
                  const updatedSelection = { ...prev }
                  if (updatedSelection[item.processId]) {
                    delete updatedSelection[item.processId]
                  } else {
                    updatedSelection[item.processId] = item
                  }
                  return updatedSelection
                })
              }
            >
              {item?.processName}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TransferLists
