import './index.css'
import React from 'react'

const LogContent = ({ data, dataIndex }) => {
  if (!data || !data.body) {
    return null
  }

  const { body } = data

  return (
    <div className="tab-content-container">
      <div className="request-block">
        <div className="request-header">Request</div>
        <div className="request-body">
          <pre>{JSON.stringify(body[dataIndex - 1]?.request, null, 2)}</pre>
        </div>
      </div>

      {body[dataIndex - 1]?.response && (
        <div className="response-block">
          <div className="response-header">Response</div>
          <div className="response-body">
            <pre>{JSON.stringify(body[dataIndex - 1]?.response, null, 2)}</pre>
          </div>
        </div>
      )}

      {body[dataIndex - 1]?.error && (
        <div className="error-block">
          <div className="error-header">Error</div>
          <div className="error-body">
            <pre>{JSON.stringify(body[dataIndex - 1]?.error, null, 2)}</pre>
          </div>
        </div>
      )}

      {body[dataIndex - 1]?.success && (
        <div className="success-block">
          <div className="success-header">Success</div>
          <div className="success-body">
            <pre>{JSON.stringify(body[dataIndex - 1]?.success, null, 2)}</pre>
          </div>
        </div>
      )}
    </div>
  )
}

export default LogContent
