import './index.css'
import { useEffect, useState } from 'react'
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material'

const ProcessPopover = ({ processList, handleSubmit, handleClose }) => {
  const [processFormData, setProcessFormData] = useState({})

  const createFormData = () => {
    let formData = {}
    processList.forEach((process) => {
      formData[process.processId] = process.dashboardIndicator
    })

    setProcessFormData({ ...formData })
  }
  useEffect(() => {
    createFormData()
  }, [])

  const isProcessChecked = (processId) => {
    return processFormData[processId] ? processFormData[processId] : false
  }

  const toggleProcessSelection = (event) => {
    const { name, checked } = event.target
    setProcessFormData({
      ...processFormData,
      [name]: checked,
    })
  }

  const handleSubmitClick = () => {
    let updatedProcessList = []
    Object.keys(processFormData).forEach((formDataKey) => {
      updatedProcessList.push({
        processId: formDataKey,
        dashboardIndicator: processFormData[formDataKey],
      })
    })

    handleClose()
    handleSubmit(updatedProcessList)
  }

  return (
    <div className="process-popover-container">
      <FormGroup>
        {processList.map((processListItem) => {
          return (
            <FormControlLabel
              key={`process__${processListItem.processId}`}
              control={
                <Checkbox
                  name={processListItem.processId}
                  checked={isProcessChecked(processListItem.processId)}
                  onChange={toggleProcessSelection}
                />
              }
              label={processListItem.processName}
            />
          )
        })}
      </FormGroup>

      <div className="process-popover-actions-container">
        <Button
          variant="text"
          size="small"
          className="cancel-button"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          className="save-button"
          onClick={handleSubmitClick}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default ProcessPopover
