import { combineReducers } from 'redux'
import notificationReducer from 'src/reducers/notificationReducer'
import processLogReducer from 'src/reducers/processLogReducer'
import loadingReducer from 'src/reducers/loadingReducer'

const allReducers = combineReducers({
  notificationReducer,
  processLogReducer,
  loadingReducer,
})

export default allReducers
