import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem,} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import ProfileCard from './components/profilecard'
import logo from './assets/logo.svg'
import user from './assets/user.svg'
import SignOut from './assets/sign-out.svg'
import closeIcon from './assets/close-icon.svg'
import profileSettings from './assets/profile-settings.svg'
import profileExpand from './assets/profile-expand.svg'
import viewProcessLogsMenuIcon from './assets/view-process-logs-menu-icon.svg'
import manageSystemsMenuIcon from './assets/manage-systems-menu-icon.svg'
import manageUsersMenuIcon from './assets/manage-users-menu-icon.svg'
import {emailValidation, isValidMobileNumber, NOTIFICATION_TYPES,} from './utils/constants'
import api from './api'
import {getEncodedText, getFullName, getUserFormObject,} from 'src/utils/commonMethods'
import {clearProcessData, showNotificationMessage} from 'src/actions'
import {useDispatch} from 'react-redux'

const menuOptions = [
  {
    label: 'View Process Logs',
    icon: viewProcessLogsMenuIcon,
    path: '/filter-bpm',
  },
  {
    label: 'Manage Systems',
    icon: manageSystemsMenuIcon,
    path: '/manage-systems-processes',
  },
  {
    label: 'Manage Users',
    icon: manageUsersMenuIcon,
    path: '/manage-users',
  },
  // Add more menu options as needed
]

const AppMenu = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  React.useEffect(() => {
    getUserProfile()
  }, [])

  // const currentUserEmail = useSelector((state) => state.user.userEmail);
  const currentUserEmail = sessionStorage.getItem('userEmail') ?? ''

  const [loggedUserName, setLoggedUserName] = React.useState('')
  const [isProfileSettingsDialogOpen, setIsProfileSettingsDialogOpen] =
    React.useState(false)
  const [scroll] = React.useState('paper')

  const [routeMenuEl, setRouteMenuEl] = React.useState(null)
  const isRouteMenuOpen = Boolean(routeMenuEl)
  const handleToggleRouteMenu = (event) => {
    setRouteMenuEl(event?.currentTarget)
  }

  const [settingsMenuEl, setSettingsMenuEl] = React.useState(null)
  const isSettingsMenuOpen = Boolean(settingsMenuEl)
  const handleToggleSettingsMenu = (event) => {
    setSettingsMenuEl(event?.currentTarget)
  }

  const [userProfileObj, setUserProfileObj] = React.useState({
    userId: '',
    userFirstName: '',
    userLastName: '',
    userMobile: '',
    userDept: '',
    userDesignation: '',
    userEmail: '',
    roleId: '',
    password: '',
    confirmPassword: '',
    activeIndicator: false,
  })

  const getUserProfile = async () => {
    try {
      const response = await api.getUserProfile(currentUserEmail)
      if (response.status === 200) {
        setUserProfileObj(getUserFormObject(response.data))
        setLoggedUserName(
          response.data.firstName + ' ' + response.data.lastName
        )
      } else {
        navigate('/')
      }
    } catch (error) {
      navigate('/')
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Please Login again`,
        })
      )
    }
  }

  const userProfileFormHandler = (event, type) => {
    if (type === 'activeIndicator') {
      setUserProfileObj({
        ...userProfileObj,
        [type]: event,
      })
    } else {
      setUserProfileObj({
        ...userProfileObj,
        [type]: event.target.value,
      })
    }
  }

  const handleProfileSettingsDialogOpen = async () => {
    setIsProfileSettingsDialogOpen(true)
  }

  const handleProfileSettingDialogClose = () => {
    setIsProfileSettingsDialogOpen(false)
    handleToggleSettingsMenu()
  }

  const handleProfileSave = async () => {
    try {
      if (!emailValidation(userProfileObj.userEmail)) {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Provided Email address is invalid`,
          })
        )
        return false
      }

      if (!isValidMobileNumber(userProfileObj.userMobile)) {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Provided Mobile Number is invalid`,
          })
        )
        return false
      }

      if (
        userProfileObj.password === '' ||
        userProfileObj.confirmPassword === ''
      ) {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Please enter valid password.`,
          })
        )
        return false
      }

      if (userProfileObj.password !== userProfileObj.confirmPassword) {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Password and Confirm Password should be same`,
          })
        )
        return false
      }

      let encodedUserObject = {
        ...userProfileObj,
        password: getEncodedText(userProfileObj.password),
      }

      delete encodedUserObject.confirmPassword

      const response = await api.addUser(encodedUserObject)
      if (response.status === 201) {
        setIsProfileSettingsDialogOpen(false)
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.SUCCESS,
            message: `'${getFullName([
              userProfileObj.firstName,
              userProfileObj.lastName,
            ])}' User has been updated successfully.`,
          })
        )
      } else {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Encountered an error while updating '${getFullName([
              userProfileObj.firstName,
              userProfileObj.lastName,
            ])}'.`,
          })
        )
      }
    } catch (e) {
      setIsProfileSettingsDialogOpen(false)
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: 'Unable to update user. Reason : ' + e,
        })
      )
      return false
    }
  }

  const handleSignOut = () => {
    sessionStorage.clear()
    navigate('/')
  }

  const handleNavigation = (url) => {
    dispatch(clearProcessData())
    handleToggleRouteMenu()
    navigate(url)
  }

  const handleDashboardNavigation = () => {
    handleToggleRouteMenu()
    navigate('/dashboard')
  }

  return (
    <>
      <Menu
        id="routesMenu"
        anchorEl={routeMenuEl}
        open={isRouteMenuOpen}
        onClose={() => {
          handleToggleRouteMenu()
        }}
        sx={{
          '& .MuiList-root': {
            padding: 'unset !important',
            borderRadius: 'unset !important',
          },
          '& .MuiMenu-paper': {
            borderRadius: 'unset !important',
            backgroundColor: '#175da0 !important',
          },
        }}
      >
        {menuOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleNavigation(option.path)
            }}
            sx={{
              padding: 'unset !important',
            }}
            tabIndex={-1}
          >
            <div
              className={`menu-option ${index !== menuOptions.length - 1 ? 'menu-option-with-border' : ''}`}
            >
              <img src={option.icon} alt={`${option.label}Icon`} />
              <span className="user-menu-options-text">{option.label}</span>
            </div>
          </MenuItem>
        ))}
      </Menu>

      <Menu
        id="settingsMenu"
        anchorEl={settingsMenuEl}
        open={isSettingsMenuOpen}
        onClose={() => {
          handleToggleSettingsMenu()
        }}
        sx={{
          '& .MuiList-root': {
            padding: 'unset !important',
            borderRadius: 'unset !important',
          },
          '& .MuiMenu-paper': {
            borderRadius: 'unset !important',
            backgroundColor: '#175da0 !important',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleProfileSettingsDialogOpen()
          }}
          sx={{
            padding: 'unset !important',
          }}
          tabIndex={-1}
        >
          <div className={`menu-option menu-option-with-border`}>
            <img src={profileSettings} alt="profileSettings" />
            <span className="user-menu-options-text">Profile Settings</span>
          </div>
        </MenuItem>

        <MenuItem
          onClick={handleSignOut}
          sx={{
            padding: 'unset !important',
          }}
          tabIndex={-1}
        >
          <div className={`menu-option`}>
            <img src={SignOut} alt="SignOut" />
            <span className="user-menu-options-text" onClick={handleSignOut}>
              SignOut
            </span>
          </div>
        </MenuItem>
      </Menu>

      <div className="container">
        <div className="app-menu-block" onClick={handleDashboardNavigation}>
          <img src={logo} alt="logo" />
        </div>

        <div className="app-menu-block" onClick={handleToggleRouteMenu}>
          {isRouteMenuOpen ? (
            <CloseIcon sx={{ color: 'white', fontSize: 40 }} />
          ) : (
            <MenuIcon sx={{ color: 'white', fontSize: 40 }} />
          )}
        </div>
        <div className="app-menu-block"></div>
        <div className="app-menu-block">
          {/*<div className="menu-help-text">Help</div>*/}
          {/*<img className="menu-help-image" src={help} alt="help" />*/}
        </div>
        <div className="app-menu-block">
          <div className="p-5">
            <img className="menu-logged-user-image" src={user} alt="user" />
          </div>
          <div
            className="menu-logged-user-info"
            id="basic-button"
            onClick={handleToggleSettingsMenu}
          >
            <div className="menu-logged-user-text">Welcome to</div>
            <div className="menu-logged-user-name">
              {loggedUserName} <img src={profileExpand} alt="profileExpand" />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={isProfileSettingsDialogOpen}
        onClose={handleProfileSettingDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className="adduser_dialog_header">
          Profile Settings
          <Button
            className="closeButton"
            onClick={handleProfileSettingDialogClose}
          >
            <img
              className="close_icon_img"
              src={closeIcon}
              title="Close"
              alt="Close"
            ></img>
          </Button>
        </DialogTitle>
        <DialogContent
          dividers={scroll === 'paper'}
          className="adduser_dialog_content"
        >
          <ProfileCard
            userFormHandler={userProfileFormHandler}
            createUser={userProfileObj}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <div
            className="cancel-text"
            onClick={handleProfileSettingDialogClose}
          >
            Cancel
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleProfileSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AppMenu
