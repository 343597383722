import { reducerActions } from 'src/actions/actionTypes'

const initialState = {
  processName: null,
  processData: null,
}

const processLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case reducerActions.UPDATE_PROCESS_DATA:
      return {
        ...state,
        processName: action.payload.processName,
        processData: action.payload.processData,
      }

    case reducerActions.CLEAR_PROCESS_DATA:
      return {
        processName: null,
        processData: null,
      }
    default:
      return state
  }
}
export default processLogReducer
