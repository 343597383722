import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import { FormHelperText } from '@mui/material'
import React from 'react'
import dayjs from "dayjs";

const CustomDateTimePicker = ({
  id,
  name,
  label,
  slotProps,
  onChange,
  value,
  sx,
  error,
  errorText,
}) => {
  const handleChange = (event) => {
    onChange({
      target: {
        name,
        value: event.format(),
      },
    })
  }

  const getValue = () => {
    if (value && value !== '') {
      return dayjs(value);
    }
    return null;
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          id={id}
          label={label}
          name={name}
          orientation="landscape"
          slotProps={{
            ...slotProps,
          }}
          views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
          onChange={handleChange}
          value={getValue()}
          sx={{
            '& .MuiOutlinedInput-root': {
              fontStyle: 'italic !important',
            },
            ...sx,
          }}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
        />
      </LocalizationProvider>
      {error ? (
        <FormHelperText className={'form-control-error'}>
          {errorText}
        </FormHelperText>
      ) : null}
    </>
  )
}

export default CustomDateTimePicker
