import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { dashboardRoutes } from 'src/routes'
import AppMenu from 'src/AppMenu'

const Layout = () => {
  return (
    <>
      <AppMenu />

      <Routes>
        {dashboardRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Routes>
    </>
  )
}

export default Layout
