const isFormControlError = (form, key) => {
  return form.touched[key] && Boolean(form.errors[key])
}

const getFormControlErrorText = (form, key) => {
  return form.touched[key] && form.errors[key]
}

const getProcessLogFilterForm = (identifierList) => {
  return identifierList.reduce(
    (acc, cur) => ({ ...acc, [cur.identifierId]: '' }),
    {
      fromDateTime: '',
      toDateTime: '',
    }
  )
}

const getFilterIdentifierFormData = (identifierList, formData) => {
  const formIdentifierKeys = []
  const formIdentifierValues = []
  identifierList?.forEach((listItem) => {
    if (formData[listItem.identifierId]) {
      formIdentifierKeys.push(listItem.identifierName)
      formIdentifierValues.push(formData[listItem.identifierId])
    }
  })
  return {
    formIdentifierKeys,
    formIdentifierValues,
  }
}

export {
  isFormControlError,
  getFormControlErrorText,
  getProcessLogFilterForm,
  getFilterIdentifierFormData,
}

// Entity ID/ Key
//
// PIM ID
//
// Trace ID
//
// Service Name
//
// Time Stamp
//
// Status (Request / Response / Error)
