import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

const CustomDatePicker = ({
  id,
  name,
  placeholder,
  error,
  helperText,
  value,
  onChange,
  sx,
  className,
  closePopover = () => {},
  minimumDate,
  maximumDate,
  ...props
}) => {
  const handleOnChange = (newValue) => {
    onChange({
      target: {
        name,
        value: newValue ? dayjs(newValue).format('YYYY-MM-DD') : '',
      },
    })
  }

  const getValue = () => {
    if (value && value !== '') {
      return dayjs(value)
    }
    return null
  }
  const closeCalender = () => {
    closePopover()
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...props}
          id={id}
          name={name}
          minDate={minimumDate ? minimumDate : null}
          maxDate={maximumDate ? maximumDate : null}
          value={getValue()}
          onChange={handleOnChange}
          sx={sx}
          className={className}
          format="DD/MM/YYYY"
          views={['year', 'month', 'day']}
          onClose={closeCalender}
          slotProps={{
            field: { clearable: true },
            textField: {
              variant: 'outlined',
              error: error,
              helperText: helperText,
              placeholder: placeholder,
            },
          }}
        />
      </LocalizationProvider>
    </>
  )
}

export default CustomDatePicker
