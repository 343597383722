import './ToggleButton.css'
import React, { Component } from 'react'

class ToggleButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: props.checked || false,
    }
  }

  handleToggle = () => {
    this.setState(
      (prevState) => ({ checked: !prevState.checked }),
      () => {
        // Callback to notify parent component or perform any action after state update.
        this.props.onClick(this.state.checked)
      }
    )
  }
  render() {
    return (
      <>
        <div className="toggle-button-main-cover">
          <div className="toggle-button-cover">
            <div className="button-cover">
              <div className="button b2" id="button-16">
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={this.handleToggle}
                  value={this.state.checked}
                  checked={this.state.checked}
                />
                <div className="knobs"></div>
                <div className="layer"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ToggleButton
