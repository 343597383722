import React, { useEffect, useState } from 'react'
import { NOTIFICATION_TYPES, setDateFormat } from '../utils/constants'
import loginLogout from '../assets/login-logout.svg'
import api from '../api'
import { showNotificationMessage } from 'src/actions'
import { useDispatch } from 'react-redux'

const UserLoginLogs = ({ selectedUserId }) => {
  const dispatch = useDispatch()
  const [userLoginLogsObj, setUserLoginLogsObj] = useState([])
  const geUserLoginLogs = async () => {
    try {
      const response = await api.getUserLoginLogoutInfo(selectedUserId)
      if (response.status === 200) {
        setUserLoginLogsObj(response.data)
      } else {
        setUserLoginLogsObj([])
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Encountered an Error while fetching user timelogs`,
          })
        )
      }
    } catch (error) {
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  useEffect(() => {
    geUserLoginLogs()
  }, [selectedUserId])

  return (
    <div className="last-login-logout-details-block">
      <table className="last-login-logout-details-table">
        <thead>
          <tr>
            <th>Last Login</th>
            <th>&nbsp;</th>
            <th>Last Logout</th>
          </tr>
        </thead>
        <tbody>
          {userLoginLogsObj.length !== 0 ? (
            userLoginLogsObj?.map((record, index) => (
              <tr key={index}>
                <td>{setDateFormat(record?.sessionStartDate)}</td>
                <td>
                  <img
                    src={loginLogout}
                    title="loginLogout"
                    alt="loginLogout"
                  ></img>
                </td>
                <td>{setDateFormat(record?.sessionEndDate)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No info found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default UserLoginLogs
