import {
  CircularProgress,
  Dialog,
  DialogContent,
  makeStyles,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

const LoadingModal = () => {
  const classes = useStyles()
  const loadingReducer = useSelector((state) => state.loadingReducer)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loadingReducer) {
      setLoading(loadingReducer.loading)
    }
  }, [loadingReducer])

  return (
    <Dialog open={loading}>
      <DialogContent className={classes.loadingContainer}>
        <CircularProgress />
        <span className={classes.loadingText}>Please wait a moment ....</span>
      </DialogContent>
    </Dialog>
  )
}

export default LoadingModal

const useStyles = makeStyles({
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingText: {
    fontSize: '14px',
    color: '#112f3c',
  },
})
