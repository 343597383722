import './index.css'
import api from 'src/api'
import { showNotificationMessage, updateLoadingState } from 'src/actions'
import { FORM_ERROR_MESSAGES, NOTIFICATION_TYPES } from 'src/utils/constants'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import { DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getDateTime } from 'src/utils/commonMethods'
import {
  getFormControlErrorText,
  isFormControlError,
} from 'src/services/formHelperService'
import AppMenu from 'src/AppMenu'
import ProcessMonitorItem from 'src/components/ProcessMonitorItem'
import LogContent from 'src/pages/ProcessLogs/LogContent'
import CustomDateTimePicker from 'src/components/common/CustomDateTimePicker'

const ProcessLogs = () => {
  const dispatch = useDispatch()
  const reducerData = useSelector((state) => state.processLogReducer)
  const [processList, setProcessList] = useState([])
  const [processQueryLogsList, setProcessQueryLogsList] = useState([])
  const [queryLogData, setQueryLogData] = useState(null)
  const [activeTab, setActiveTab] = useState(1)
  const [selectedProcess, setSelectedProcess] = useState(null)

  const initialValues = {
    fromDateTime: null,
    toDateTime: null,
    processId: '',
    traceId: '',
  }

  const telemetryForm = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      fromDateTime: Yup.date()
        .required(FORM_ERROR_MESSAGES.FROM_DATE_REQUIRED)
        .test(
          'is-less',
          'From date must be less than To date',
          function (value) {
            const { toDateTime } = this.parent
            if (!value || !toDateTime) return true // Skip validation if either field is empty
            return new Date(value) < new Date(toDateTime)
          }
        ),
      toDateTime: Yup.date().required(FORM_ERROR_MESSAGES.TO_DATE_REQUIRED),
      processId: Yup.string().required(FORM_ERROR_MESSAGES.PROCESS_ID_REQUIRED),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      handleSubmit(values)
    },
    enableReinitialize: true,
  })

  const fetchProcesses = async () => {
    dispatch(updateLoadingState(true))
    try {
      const response = await api.getAllProcesses(
        sessionStorage.getItem('userId') ?? ''
      )
      setProcessList(response.data ?? [])
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  const fetchDataOnReduxState = () => {
    const filteredProcess = processList.filter(
      (listItem) => listItem.processName === reducerData.processName
    )
    if (filteredProcess.length) {
      setSelectedProcess(filteredProcess[0])
      telemetryForm.setFieldValue(
        'processId',
        filteredProcess[0].processId,
        true
      )
      showQueryLogs(reducerData.processData.traceId, reducerData.processName)
    }
  }

  useEffect(() => {
    fetchProcesses()
  }, [])

  useEffect(() => {
    updateSelectedProcess()
  }, [telemetryForm.values.processId])

  useEffect(() => {
    if (processList.length && reducerData.processName) {
      fetchDataOnReduxState()
    }
  }, [processList, reducerData])

  const updateSelectedProcess = () => {
    const filteredProcess = processList.filter(
      (listItem) => listItem.processId === telemetryForm.values.processId
    )
    if (filteredProcess.length) {
      setSelectedProcess(filteredProcess[0])
    }
  }

  const handleSubmit = async (values) => {
    await getQueryLogsByProcessId(values)
  }

  const getQueryLogsByProcessId = async (values) => {
    setProcessQueryLogsList([])
    dispatch(updateLoadingState(true))
    try {
      const fromDateTime = dayjs(values.fromDateTime).valueOf()
      const toDateTime = dayjs(values.toDateTime).valueOf()

      const requestBody = {
        fromDate: fromDateTime,
        toDate: toDateTime,
        keyNames: [],
        values: [],
        dashboard: false,
      }

      const response = await api.queryLogs(
        selectedProcess.processName,
        requestBody
      )
      setProcessQueryLogsList(response.data ?? [])
      setQueryLogData(null)
      setActiveTab(1)
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  const handleProcessClick = async (processData) => {
    await showQueryLogs(processData.traceId)
  }

  const showQueryLogs = async (
    selectedTraceId,
    processName = selectedProcess.processName
  ) => {
    setQueryLogData(null)
    dispatch(updateLoadingState(true))
    try {
      const response = await api.showLogs(selectedTraceId, processName)

      setQueryLogData(response.data)
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  const handleSelTabClick = (index) => {
    setActiveTab(index + 1)
  }

  const showProcessMonitor = () => {
    return telemetryForm.submitCount && processQueryLogsList?.length
  }

  return (
    <>
      <div className="process-logs-container">
        <form className="process-logs-form-container">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <CustomDateTimePicker
                  id="fromDateTime"
                  name="fromDateTime"
                  slotProps={{ textField: { size: 'medium' } }}
                  label="From Date Time *"
                  onChange={(event) => {
                    telemetryForm.setFieldValue(
                      event.target.name,
                      event.target.value,
                      true
                    )
                  }}
                  value={getDateTime(telemetryForm.values.fromDateTime)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      fontStyle: 'italic !important',
                    },
                    '& .MuiInputBase-input': {
                      height: 'unset !important',
                    },
                  }}
                  error={isFormControlError(telemetryForm, 'fromDateTime')}
                  errorText={getFormControlErrorText(
                    telemetryForm,
                    'fromDateTime'
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <CustomDateTimePicker
                  id="toDateTime"
                  name="toDateTime"
                  label="To Date Time *"
                  slotProps={{ textField: { size: 'medium' } }}
                  onChange={(event) => {
                    telemetryForm.setFieldValue(
                      event.target.name,
                      event.target.value,
                      true
                    )
                  }}
                  value={getDateTime(telemetryForm.values.toDateTime)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      fontStyle: 'italic !important',
                    },
                    '& .MuiInputBase-input': {
                      height: 'unset !important',
                    },
                  }}
                  error={isFormControlError(telemetryForm, 'toDateTime')}
                  errorText={getFormControlErrorText(
                    telemetryForm,
                    'toDateTime'
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth size={'medium'}>
                <InputLabel>Select Process</InputLabel>
                <Select
                  id="processId"
                  name="processId"
                  label="Select Process"
                  placeholder="Process"
                  value={telemetryForm.values.processId}
                  onChange={telemetryForm.handleChange}
                  onBlur={telemetryForm.handleBlur}
                >
                  <MenuItem value="">
                    <em>Select Process</em>
                  </MenuItem>
                  {processList.map((processListItem) => (
                    <MenuItem
                      key={processListItem.processId}
                      value={processListItem.processId}
                    >
                      {processListItem.processName}
                    </MenuItem>
                  ))}
                </Select>
                {isFormControlError(telemetryForm, 'processId') ? (
                  <FormHelperText className={'form-control-error'}>
                    {getFormControlErrorText(telemetryForm, 'processId')}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                color="primary"
                className="process-logs-telemetry-button"
                onClick={() => telemetryForm.submitForm()}
              >
                Get Telemetry
              </Button>
            </Grid>
          </Grid>
        </form>

        {showProcessMonitor() ? (
          <div className={'process-log-list-container'}>
            <ProcessMonitorItem
              processKey={selectedProcess.processName}
              processData={processQueryLogsList}
              onProcessClick={handleProcessClick}
              hideDateFilter={true}
              processDetails={selectedProcess}
              selectedFromDateTime={telemetryForm.values.fromDateTime}
              selectedToDateTime={telemetryForm.values.toDateTime}
            />
          </div>
        ) : null}

        {queryLogData ? (
          <div className="query-log-container">
            <div className="tabs">
              {queryLogData?.serviceNames?.map((serviceName, index) => (
                <div
                  key={index}
                  className={activeTab === index + 1 ? 'tab active' : 'tab'}
                  onClick={() => handleSelTabClick(index)}
                >
                  {serviceName}
                </div>
              ))}
            </div>

            <div className="tab-content">
              <LogContent data={queryLogData} dataIndex={activeTab} />
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default ProcessLogs
