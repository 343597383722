import React from 'react'
import './components.css'

const Switch = ({ isOn, handleToggle, index = 0 }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="switch-checkbox"
        id={`switch` + index}
        type="checkbox"
      />
      <label
        style={{ background: isOn ? '#1967B2' : '#CDD6DE' }}
        className="switch-label"
        htmlFor={`switch` + index}
      >
        <span className={`switch-button`} />
      </label>
      <span className="switch-status">{isOn ? 'Active' : 'InActive'}</span>
    </>
  )
}

export default Switch
