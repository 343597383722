import home from './pages/home'
import manageUsers from 'src/pages/ManageUsers/manageUsers'
import ManageSystemsProcesses from './pages/manageSystemsProcesses'
import dashboard from './pages/dashboard'
import LoginPage from './pages/login'
import ProcessLogs from 'src/pages/ProcessLogs'
import Layout from 'src/pages/layout'

export const routes = [
  {
    key: 'login',
    title: 'Login',
    path: '/',
    component: LoginPage,
  },
  {
    key: 'dashboard',
    title: 'Dashboard',
    path: '*',
    component: Layout,
  },
]

const dashboardRoutes = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    path: '/dashboard/',
    component: dashboard,
  },
  {
    key: 'home',
    title: 'Home',
    path: '/home/',
    component: home,
  },
  {
    key: 'manage-users',
    title: 'Manage Users',
    path: '/manage-users/',
    component: manageUsers,
  },
  {
    key: 'filter-bpm',
    title: 'Filter BPMs',
    path: '/filter-bpm/',
    component: ProcessLogs,
  },
  {
    key: 'manage-systems-processes',
    title: 'Manage Systems & Processes',
    path: '/manage-systems-processes/',
    component: ManageSystemsProcesses,
  },
]

export { dashboardRoutes }
