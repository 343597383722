import { Button, FormControl, Grid } from '@mui/material'
import React from 'react'
import CustomDateTimePicker from 'src/components/common/CustomDateTimePicker'

const DateFilterForm = ({ filterForm, handleChange, handleSubmit }) => {
  return (
    <>
      <div>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={12} md={5}>
            <FormControl fullWidth>
              <CustomDateTimePicker
                id="fromDateTime"
                name="fromDateTime"
                slotProps={{ textField: { size: 'medium' } }}
                label="From Date Time *"
                onChange={handleChange}
                value={filterForm.fromDateTime}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    fontStyle: 'italic !important',
                  },
                  '& .MuiInputBase-input': {
                    height: 'unset !important',
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={5}>
            <FormControl fullWidth>
              <CustomDateTimePicker
                id="toDateTime"
                name="toDateTime"
                slotProps={{ textField: { size: 'medium' } }}
                label="To Date Time *"
                onChange={handleChange}
                value={filterForm.toDateTime}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    fontStyle: 'italic !important',
                  },
                  '& .MuiInputBase-input': {
                    height: 'unset !important',
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button variant="contained" onClick={handleSubmit}>
              Get Telemetry
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default DateFilterForm
