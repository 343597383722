import { reducerActions } from 'src/actions/actionTypes'

export const showNotificationMessage = (notification) => {
  return {
    type: reducerActions.SHOW_NOTIFICATION,
    payload: notification,
  }
}

export const updateProcessData = (data) => {
  return {
    type: reducerActions.UPDATE_PROCESS_DATA,
    payload: data,
  }
}

export const clearProcessData = () => {
  return {
    type: reducerActions.CLEAR_PROCESS_DATA,
  }
}
export const updateLoadingState = (loading) => {
  return {
    type: reducerActions.UPDATE_LOADING,
    payload: { loading },
  }
}
