import axios from 'axios'

const BASE_URL = process.env.REACT_APP_ALT_BASE_SERVER_URL

export const loginApi = async (email, password) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/login`,
      {
        email,
        password,
      },
      {
        withCredentials: true,
      }
    )
    return response
  } catch (error) {
    console.error('Error in loginApi:', error)
    throw error
  }
}

export const getAllUsers = async () => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.get(`${BASE_URL}/getAllUsers`, {
      withCredentials: true,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    })
    return response
  } catch (error) {
    console.error('Error in getAllUsers:', error)
    throw error
  }
}

export const getUserLoginLogoutInfo = async (userid) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.get(`${BASE_URL}/getLoginLogs/${userid}`, {
      withCredentials: true,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    })

    return response
  } catch (error) {
    console.error('Error in getLoginLogs:', error)
    throw error
  }
}

export const getAllProcessBySystemIdAndUserId = async (systemId, userid) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.get(
      `${BASE_URL}/getAllProcessBySystemIdAndUserId/${systemId}/${userid}`,
      {
        withCredentials: true,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
    )

    return response
  } catch (error) {
    console.error('Error in getAllProcessBySystemIdAndUserId:', error)
    throw error
  }
}

export const addUser = async (addUserObj) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.post(`${BASE_URL}/addUser`, addUserObj, {
      withCredentials: true,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    })
    return response
  } catch (error) {
    console.error('Error in addUser:', error)
    throw error
  }
}

export const getUserProfile = async (emailId) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.get(
      `${BASE_URL}/getUserProfile?emailId=${emailId}`,
      {
        withCredentials: true,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
    )

    return response
  } catch (error) {
    console.error('Error in getUserProfile:', error)
    throw error
  }
}

export const changeStatusOfUser = async (userid, statusVal) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.get(
      `${BASE_URL}/changeStatusOfUser/${userid}/${statusVal}`,
      {
        withCredentials: true,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
    )

    return response
  } catch (error) {
    console.error('Error in changeStatusOfUser:', error)
    throw error
  }
}

export const assignProcessToUser = async (userid, systemId, processObj) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.post(
      `${BASE_URL}/assignProcessToUser/${userid}/${systemId}`,
      processObj,
      {
        withCredentials: true,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
    return response
  } catch (error) {
    console.error('Error in loginApi:', error)
    throw error
  }
}

/* GET 'systemMaster' */

export const getSystemMaster = async () => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.get(`${BASE_URL}/systemMaster`, {
      withCredentials: true,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    })

    return response
  } catch (error) {
    console.error('Error in systemMaster:', error)
    throw error
  }
}

export const getAllSystemProcessBySystemId = async (systemId) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.get(
      `${BASE_URL}/getAllSystemProcessBySystemId/${systemId}`,
      {
        withCredentials: true,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
    )

    return response
  } catch (error) {
    console.error('Error in getAllSystemProcessBySystemId:', error)
    throw error
  }
}

/* POST 'systemMaster' */

export const postSystemMaster = async (
  systemIdVal,
  systemNameVal,
  activeIndicatorVal
) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.post(
      `${BASE_URL}/systemMaster`,
      {
        systemId: systemIdVal,
        systemName: systemNameVal,
        activeIndicator: activeIndicatorVal,
      },
      {
        withCredentials: true,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
    return response
  } catch (error) {
    console.error('Error in POST systemMaster:', error)
    throw error
  }
}

export const getAllRoles = async () => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.get(`${BASE_URL}/getAllRoles`, {
      withCredentials: true,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    })

    return response
  } catch (error) {
    console.error('Error in getAllRoles:', error)
    throw error
  }
}

export const dashboardLogs = async (emailId) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.get(`${BASE_URL}/dashboardLogs/${emailId}`, {
      withCredentials: true,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    })

    return response
  } catch (error) {
    console.error('Error in dashboardLogs:', error)
    throw error
  }
}

export const queryLogs = async (processName, formData) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.post(
      `${BASE_URL}/queryLogs/${processName}`,
      {
        ...formData,
      },
      {
        withCredentials: true,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
    return response
  } catch (error) {
    console.error('Error in queryLogs:', error)
    throw error
  }
}

export const getAllProcesses = async (userId) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.get(`${BASE_URL}/getAllProcesses/${userId}`, {
      withCredentials: true,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    })

    return response
  } catch (error) {
    console.error('Error in getAllProcesses:', error)
    throw error
  }
}

export const showLogs = async (traceId, processName) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.get(
      `${BASE_URL}/showLogs/${traceId}/${processName}`,
      {
        withCredentials: true,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
    )

    return response
  } catch (error) {
    console.error('Error in showLogs:', error)
    throw error
  }
}

export const createOrUpdateSystemProcess = async (formData) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    const response = await axios.post(
      `${BASE_URL}/createOrUpdateSystemProcess`,
      {
        ...formData,
      },
      {
        withCredentials: true,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
    return response
  } catch (error) {
    console.error('Error in queryLogs:', error)
    throw error
  }
}
const addOrRemoveProcessFromDashboard = async (userId, processList) => {
  const userToken = sessionStorage.getItem('userToken')
  try {
    return await axios.post(
      `${BASE_URL}/addOrRemoveProcessFromDashboard/${userId}`,
      processList,
      {
        withCredentials: true,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
  } catch (error) {
    throw error
  }
}

export default {
  loginApi,
  getAllUsers,
  getUserLoginLogoutInfo,
  getAllProcessBySystemIdAndUserId,
  addUser,
  getUserProfile,
  changeStatusOfUser,
  getSystemMaster,
  assignProcessToUser,
  getAllSystemProcessBySystemId,
  postSystemMaster,
  getAllRoles,
  dashboardLogs,
  queryLogs,
  getAllProcesses,
  showLogs,
  createOrUpdateSystemProcess,
  addOrRemoveProcessFromDashboard,
}
