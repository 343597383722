import React, { useEffect, useRef, useState } from 'react'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from '@mui/material'
import AppMenu from 'src/AppMenu'
import Switch from 'src/components/switch'
import ProfileCard from 'src/components/profilecard'
import expand from 'src/assets/expand-icon.svg'
import addUser from 'src/assets/add-user.svg'
import edit from 'src/assets/edit.svg'
import closeicon from 'src/assets/close-icon.svg'
import TransferLists from 'src/components/transferlists'
import EditUser from 'src/components/editUser'
import {
  emailValidation,
  FORM_ERROR_MESSAGES,
  isValidMobileNumber,
  NOTIFICATION_TYPES,
  setDateFormat,
} from 'src/utils/constants'
import api from 'src/api'
import UserLoginLogs from 'src/components/userLoginLogs'
import { getEncodedText, getFullName } from 'src/utils/commonMethods'
import { showNotificationMessage, updateLoadingState } from 'src/actions'
import { useDispatch } from 'react-redux'
import './manageUsers.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const UserList = () => {
  const dispatch = useDispatch()

  const [users, setUsers] = useState([])
  const [addUserOpenDialog, setAddUserOpenDialog] = React.useState(false)
  const [systemValue, setSystemValue] = React.useState('')
  const [systemValuesList, setSystemValuesList] = React.useState([{}])
  const [showLogs, setShowLogs] = React.useState(false)
  const [showProcessAssignSection, setShowProcessAssignSection] =
    React.useState(false)
  const [showEditUserForm, setShowEditUserForm] = useState(false)
  const [editUser, setEditUser] = useState({})
  const [createUser, setCreateUser] = useState({
    userId: '',
    userFirstName: '',
    userLastName: '',
    userMobile: '',
    userDept: '',
    userDesignation: '',
    userEmail: '',
    roleId: '',
    password: '',
    confirmPassword: '',
    activeIndicator: false,
  })

  const [scroll] = React.useState('paper')

  const [transferLists, setTransferLists] = useState({})

  const createUserFormHandler = (event, type) => {
    if (type === 'activeIndicator') {
      setCreateUser({
        ...createUser,
        [type]: event.target.checked,
      })
    } else {
      setCreateUser({
        ...createUser,
        [type]: event.target.value,
      })
    }
  }

  const editUserFormHandler = (event, type) => {
    setEditUser({ ...event })
  }

  const getAllUsers = async () => {
    dispatch(updateLoadingState(true))
    try {
      const response = await api.getAllUsers()
      if (response.status === 200) {
        setUsers(response.data)
      } else {
        setUsers([])
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Encountered an Error while fetching the users`,
          })
        )
      }
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  useEffect(() => {
    getAllUsers()
  }, [])

  const usersListContainerRef = useRef(null)

  useEffect(() => {
    const resizeHandler = () => {
      const container = usersListContainerRef.current

      if (container) {
        const availableHeight =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 90}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const handleUserToggle = async (toggleSelUserObj, index) => {
    // let toggleUsers = [...users];
    // toggleSelUserObj.activeIndicator = !toggleSelUserObj.activeIndicator;
    // toggleUsers[index] = toggleSelUserObj;
    // setUsers(toggleUsers);
    dispatch(updateLoadingState(true))
    try {
      const response = await api.changeStatusOfUser(
        toggleSelUserObj?.userId,
        !toggleSelUserObj?.activeIndicator
      )
      if (response.status === 200) {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.SUCCESS,
            message: `User: '${getFullName([
              createUser.firstName,
              createUser.lastName,
            ])}' status has been updated successfully.`,
          })
        )
        getAllUsers()
      } else {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `User: '${getFullName([
              createUser.firstName,
              createUser.lastName,
            ])}' status has not updated. Reason : ${response}`,
          })
        )
      }
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  const handleAddUserDialogOpen = () => {
    setAddUserOpenDialog(true)
  }

  const handleAddUserDialogClose = () => {
    setAddUserOpenDialog(false)
  }

  const handleEditUserCancel = () => {
    setShowEditUserForm(false)
    setEditUser({})
  }

  const handleEditUserSave = async (editUserObj) => {
    try {
      if (editUserObj.password === '' || editUserObj.confirmPassword === '') {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: 'Please enter valid password.',
          })
        )
        return false
      }
      if (!emailValidation(editUserObj.userEmail)) {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: 'Provided Email address is invalid',
          })
        )
        return false
      }

      if (!isValidMobileNumber(editUserObj.userMobile)) {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: 'Provided Mobile number is invalid',
          })
        )
        return false
      }

      if (editUserObj.password !== editUserObj.confirmPassword) {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: 'Password and Confirm Password should be same',
          })
        )
        return false
      }

      const updatedEditUser = {
        ...editUserObj,
        password: getEncodedText(editUserObj.password),
      }
      delete updatedEditUser.confirmPassword
      delete updatedEditUser.roleName

      dispatch(updateLoadingState(true))
      const response = await api.addUser(updatedEditUser)
      if (response.status === 201) {
        setAddUserOpenDialog(false)
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.SUCCESS,
            message: `'${getFullName([
              createUser.firstName,
              createUser.lastName,
            ])}' User has been added successfully.`,
          })
        )
        await getAllUsers()
      } else {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `'${getFullName([
              createUser.firstName,
              createUser.lastName,
            ])}' User has not been added.`,
          })
        )
      }
      dispatch(updateLoadingState(false))
    } catch (e) {
      dispatch(updateLoadingState(false))
      setAddUserOpenDialog(false)
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: 'Unable to add user. Reason : ' + e,
        })
      )
      return false
    }
  }

  const handleSystemValueChange = async (event) => {
    setSystemValue(event.target.value)
    if (event.target.value !== '') {
      dispatch(updateLoadingState(true))
      try {
        const response = await api.getAllProcessBySystemIdAndUserId(
          event.target.value,
          editUser?.userId
        )
        if (response.status === 200) {
          setTransferLists(response.data)
        } else {
          setTransferLists({})
          dispatch(
            showNotificationMessage({
              type: NOTIFICATION_TYPES.ERROR,
              message: `Encountered an Error while fetching system processes`,
            })
          )
        }
        dispatch(updateLoadingState(false))
      } catch (error) {
        dispatch(updateLoadingState(false))
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Encountered an Error: ${error}`,
          })
        )
      }
    }
  }

  const handleAddUserDialogSave = async () => {
    try {
      if (!emailValidation(createUser.userEmail)) {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Provided Email address is invalid`,
          })
        )
        return false
      }

      if (!isValidMobileNumber(createUser.userMobile)) {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Provided Mobile Number is invalid`,
          })
        )
        return false
      }

      if (createUser.password === '' || createUser.confirmPassword === '') {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Please enter valid password.`,
          })
        )
        return false
      }

      if (createUser.password !== createUser.confirmPassword) {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Password and Confirm Password should be same`,
          })
        )
        return false
      }

      let encodedUserObject = {
        ...createUser,
        password: getEncodedText(createUser.password),
      }

      delete encodedUserObject.confirmPassword

      dispatch(updateLoadingState(true))
      const response = await api.addUser(encodedUserObject)
      if (response.status === 201) {
        setAddUserOpenDialog(false)
        resetForm()
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.SUCCESS,
            message: `'${getFullName([
              createUser.firstName,
              createUser.lastName,
            ])}' User has been added successfully.`,
          })
        )
        await getAllUsers()
      } else {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `'${getFullName([
              createUser.firstName,
              createUser.lastName,
            ])}' User has not been added.`,
          })
        )
      }
      dispatch(updateLoadingState(false))
    } catch (e) {
      dispatch(updateLoadingState(false))
      setAddUserOpenDialog(false)
      resetForm()
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: 'Unable to add user. Reason : ' + e,
        })
      )
      return false
    }
  }

  const saveChanges = async () => {
    dispatch(updateLoadingState(true))
    try {
      const response = await api.assignProcessToUser(
        editUser.userId,
        systemValue,
        transferLists
      )
      if (response.status === 200) {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.SUCCESS,
            message: 'Processes have been assigned successfully.',
          })
        )
        dispatch(updateLoadingState(false))
        await getAllUsers()
      } else {
        dispatch(updateLoadingState(false))
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: 'Unable to assign Processes. Reason : ' + response,
          })
        )
      }
    } catch (e) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: 'Unable to assign Processes. Reason : ' + e,
        })
      )
      return false
    }
  }

  const cancelChanges = () => {
    setShowProcessAssignSection(false)
    setEditUser({})
  }

  const manageLoginLogoutInfo = (selUserObj) => {
    const isFormOpen = editUser.userId !== selUserObj.userId || !showLogs
    setShowLogs(isFormOpen)
    if (isFormOpen) {
      setEditUser(selUserObj)
    } else {
      setEditUser({})
    }
    setShowEditUserForm(false)
    setShowProcessAssignSection(false)
  }

  const handleAssignProcesses = async (selUserObj) => {
    const isFormOpen =
      editUser.userId !== selUserObj.userId || !showProcessAssignSection
    if (isFormOpen) {
      setEditUser(selUserObj)
      setSystemValue('')
      setTransferLists({})
    } else {
      setEditUser({})
    }
    dispatch(updateLoadingState(true))
    try {
      const response = await api.getSystemMaster()
      if (response.status === 200) {
        setSystemValuesList(response.data)
        setShowEditUserForm(false)
        setShowLogs(false)
        setShowProcessAssignSection(isFormOpen)
      } else {
        setSystemValuesList([{}])
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Encountered an Error while assigning the process`,
          })
        )
      }
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  const resetForm = () => {
    setCreateUser({
      userId: '',
      userFirstName: '',
      userLastName: '',
      userMobile: '',
      userDept: '',
      userDesignation: '',
      userEmail: '',
      roleId: '',
      password: '',
      confirmPassword: '',
      activeIndicator: false,
    })
  }

  const handleEditUser = (userData) => {
    if (showProcessAssignSection) {
      // alert("Sorry you cannot edit now");
      // return false;
    }
    const isFormOpen = editUser.userId !== userData.userId || !showEditUserForm
    setShowEditUserForm(isFormOpen)
    setEditUser({
      ...userData,
      password: '',
      confirmPassword: '',
      roleId: userData?.roleId[0],
    })
    setShowProcessAssignSection(false)
    setShowLogs(false)
  }

  return (
    <>
      <div>
        <div className="users-top-block">
          <div className="manage-users-heading">Manage Users</div>
          <Button
            variant="contained"
            color="primary"
            className="add-user-btn"
            onClick={() => {
              handleAddUserDialogOpen()
            }}
          >
            <img className="add-user-image" src={addUser} alt="Add User" />
            &nbsp;
            <div className="add-user-btn-text">Add User</div>
          </Button>
        </div>
        <div className="user-list" ref={usersListContainerRef}>
          <Grid container spacing={3}>
            {users.map((user, index) => (
              <Grid key={index} item xs={12} sm={12} md={6}>
                {user.userId !== editUser.userId ||
                showProcessAssignSection ||
                showLogs ? (
                  <div key={index} className="user-item">
                    <div className="user-item-top">
                      <div className="user-item-photo">
                        <Avatar
                          alt={user.userFirstName}
                          src="/static/images/avatar/1.jpg"
                        />
                      </div>
                      <div className="user-details">
                        <div className="user-name">
                          <span className="user-first-name">
                            {user.userFirstName}
                          </span>
                          &nbsp;
                          <span className="user-last-name">
                            {user.userLastName}
                          </span>
                        </div>
                        <div className="user-role-department">
                          {user.userDept} | {user.userDesignation}
                        </div>
                      </div>
                      <div className="user-contact-info">
                        <div className="user-phone-number">
                          {user.userMobile}
                        </div>
                        <div className="user-email">{user.userEmail}</div>
                      </div>
                      <div className="toggle-button">
                        <Switch
                          isOn={user.activeIndicator}
                          handleToggle={() => handleUserToggle(user, index)}
                          index={index}
                        />
                      </div>
                    </div>

                    <div className="user-item-bottom">
                      <div
                        className="last-login-logout-section"
                        onClick={() => {
                          manageLoginLogoutInfo(user)
                        }}
                      >
                        Last <span className="font-bold">Login:</span>{' '}
                        {setDateFormat(user.sessionStartTime)} |{' '}
                        <span className="font-bold">Logout: </span>
                        {setDateFormat(user.sessionEndTime)}
                        <img
                          className="expand-user-session-details"
                          src={expand}
                          alt="expand"
                        />
                      </div>
                      <div
                        className="assign_processes_btn"
                        onClick={() => {
                          handleAssignProcesses(user)
                        }}
                      >
                        Assign Processes
                      </div>

                      <div className="user-action-buttons-block">
                        <img
                          className="user-action-buttons"
                          src={edit}
                          alt="edit"
                          onClick={() => {
                            handleEditUser(user)
                          }}
                        />
                        {/* <img className="user-action-buttons" src={deleteIcon} alt="delete" /> */}
                      </div>
                    </div>
                    {showLogs && user.userId === editUser.userId ? (
                      <>
                        <UserLoginLogs
                          selectedUserId={user.userId}
                        ></UserLoginLogs>
                      </>
                    ) : null}

                    {showProcessAssignSection &&
                    user.userId === editUser.userId ? (
                      <div className="assign-processes-block">
                        <div className="select-system-container">
                          <FormControl>
                            <Select
                              className="select-system"
                              value={systemValue}
                              displayEmpty
                              onChange={handleSystemValueChange}
                            >
                              <MenuItem value="">
                                <em>Select System</em>
                              </MenuItem>
                              {systemValuesList.map((systValObj) => (
                                <MenuItem
                                  key={systValObj.systemId}
                                  value={systValObj.systemId}
                                >
                                  {systValObj.systemName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <TransferLists
                          leftListItems={
                            transferLists?.availableSystemProcess ?? []
                          }
                          rightListItems={
                            transferLists?.assignedSystemProcess ?? []
                          }
                          setTransferLists={(lists) => {
                            setTransferLists({
                              ...transferLists,
                              availableSystemProcess: lists.leftListItems,
                              assignedSystemProcess: lists.rightListItems,
                            })
                          }}
                          leftHeading="List of Processes"
                          rightHeading="Assigned Processes"
                        />
                        <div className="transfer-save-cancel-buttons">
                          <Button
                            variant="contained"
                            color="primary"
                            className="save-btn"
                            onClick={saveChanges}
                          >
                            Save
                          </Button>
                          <div
                            className="cancel-text mt10"
                            onClick={cancelChanges}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {showEditUserForm && user.userId === editUser.userId ? (
                  <div className="edit-user-block">
                    <EditUser
                      userObj={editUser}
                      userFormHandler={editUserFormHandler}
                      updateEditUser={() => handleEditUserSave(editUser)}
                      cancelEditUser={handleEditUserCancel}
                    />
                  </div>
                ) : null}
              </Grid>
            ))}
          </Grid>
        </div>
      </div>

      <Dialog
        open={addUserOpenDialog}
        onClose={handleAddUserDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className="adduser_dialog_header">
          Add User
          <Button
            aria-label="close"
            className="closeButton"
            onClick={handleAddUserDialogClose}
          >
            <img
              className="close_icon_img"
              src={closeicon}
              title="Close"
              alt="closeicon"
            ></img>
          </Button>
        </DialogTitle>
        <DialogContent
          dividers={scroll === 'paper'}
          className="adduser_dialog_content"
        >
          <ProfileCard
            userFormHandler={createUserFormHandler}
            createUser={createUser}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <div className="cancel-text" onClick={handleAddUserDialogClose}>
            Cancel
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddUserDialogSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UserList
