import DynamicFormControl from 'src/components/common/DynamicFormControl'
import { Button, Grid } from '@mui/material'
import React from 'react'

const IdentifierFilterForm = ({
  filterForm,
  identifierList,
  handleChange,
  handleSubmit,
}) => {
  return (
    <div className="identifier-form-container">
      <Grid container spacing={2} alignItems={'center'}>
        {identifierList.map((identifier) => {
          return (
            <Grid
              item
              xs={12}
              md={6}
              key={`filterControl__${identifier.identifierId}`}
            >
              <DynamicFormControl
                id={identifier.identifierId}
                name={identifier.identifierId}
                type={identifier.identifierType}
                value={filterForm[identifier.identifierId]}
                onChange={handleChange}
                label={identifier.identifierName}
                placeholder={identifier.identifierName}
              />
            </Grid>
          )
        })}
        <Grid item xs={12} md={2}>
          <Button variant="contained" onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
export default IdentifierFilterForm
//
// {
//   "identifierId": "5e93a870f411422f814d1411fb88ef7d",
//     "identifierName": "Test Filter 789",
//     "identifierType": "Date",
//     "serialNumber": 1,
//     "activeIndicator": true
// }
