import React, { useState } from 'react'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import defaultProfilePicture from '../assets/profile-picture.svg'
import Switch from './switch'
import api from '../api'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import SnackbarMessage from '../components/snackbarmessage'
import { showNotificationMessage } from 'src/actions'
import { NOTIFICATION_TYPES } from 'src/utils/constants'
import { useDispatch } from 'react-redux'

const EditUser = ({
  userObj,
  userFormHandler,
  updateEditUser,
  cancelEditUser,
}) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    getAllRoles()
  }, [])

  const [validation, setValidation] = useState({
    firstName: true,
    lastName: true,
    department: true,
    designation: true,
    email: true,
    phone: true,
    role: true,
    password: true,
    confirmPassword: true,
  })

  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    React.useState(false)

  const handleClickShowPassword = () => setIsPasswordVisible((show) => !show)
  const handleClickShowConfirmPassword = () =>
    setIsConfirmPasswordVisible((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const validateInput = (field, value) => {
    return value.trim() !== '' || (field === 'role' && value !== '') // Role is required
  }

  const handleInputChange = (event, field) => {
    const value = event.target.value

    handleEditUserForm(event, field)

    // Validate the input and update validation state
    setValidation({
      ...validation,
      [field]: validateInput(field, value),
    })
  }

  const getAllRoles = async () => {
    try {
      const response = await api.getAllRoles()
      if (response.status === 200) {
        setRoleValuesList(response.data)
      } else {
        setRoleValuesList([])
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Encountered an error while fetching roles.`,
          })
        )
      }
    } catch (error) {
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  const [roleValuesList, setRoleValuesList] = React.useState({})

  const [showMsgFlag, setShowMsgFlag] = React.useState(false)
  const [validationMsgStr, setValidationMsgStr] = React.useState('')

  const handleEditUserForm = (event, type) => {
    if (type === 'activeIndicator') {
      userFormHandler({
        ...userObj,
        [type]: event.target.checked,
      })
    } else {
      userFormHandler({
        ...userObj,
        [type]: event.target.value,
      })
    }
  }

  const handleEditSaveChanges = () => {
    const isValid = Object.values(validation).every((valid) => valid)
    if (isValid) {
      updateEditUser()
    } else {
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Please fill in all mandatory fields.`,
        })
      )
      return false
    }
  }

  const handleEditCancelChanges = () => {
    cancelEditUser()
  }

  return (
    <>
      <SnackbarMessage open={showMsgFlag} message={validationMsgStr} />
      <div key={userObj?.userId}>
        <Grid
          container
          spacing={3}
          columns={16}
          className="edit-user-form-container"
        >
          <Grid item xs={2}>
            <img src={defaultProfilePicture} alt="ProfilePicture" />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="First Name"
                  label="First Name"
                  value={userObj?.userFirstName}
                  onChange={(event) =>
                    handleInputChange(event, 'userFirstName')
                  }
                  error={!validation.firstName}
                  helperText={!validation.firstName && 'First Name is required'}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Last Name"
                  label="Last Name"
                  value={userObj?.userLastName}
                  onChange={(event) => handleInputChange(event, 'userLastName')}
                  error={!validation.lastName}
                  helperText={!validation.lastName && 'Last Name is required'}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Department"
                  label="Department"
                  value={userObj?.userDept}
                  onChange={(event) => handleInputChange(event, 'userDept')}
                  error={!validation.department}
                  helperText={
                    !validation.department && 'Department is required'
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className="mt5">
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Designation"
                  label="Designation"
                  value={userObj?.userDesignation}
                  onChange={(event) =>
                    handleInputChange(event, 'userDesignation')
                  }
                  error={!validation.designation}
                  helperText={
                    !validation.designation && 'Designation is required'
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Email(Login ID)"
                  label="Email(Login ID)"
                  value={userObj?.userEmail}
                  onChange={(event) => handleInputChange(event, 'userEmail')}
                  error={!validation.email}
                  helperText={
                    !validation.email && 'Email(Login ID) is required'
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Phone"
                  label="Phone"
                  value={userObj?.userMobile}
                  type="number"
                  onChange={(event) => {
                    const onlyNums = event.target.value.replace(/[^0-9]/g, '')
                    event.target.value = Math.max(0, parseInt(onlyNums))
                      .toString()
                      .slice(0, 10)
                    handleInputChange(event, 'userMobile')
                  }}
                  error={!validation.phone}
                  helperText={!validation.phone && 'Phone is required'}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className="mt5">
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    label="Role"
                    placeholder="Role"
                    className="edit-select-role"
                    value={userObj?.roleId ? userObj?.roleId : ''}
                    onChange={(event) => handleInputChange(event, 'roleId')}
                    error={!validation.role}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Select Role</em>
                    </MenuItem>
                    {Object.entries(roleValuesList).map(
                      ([roleId, roleName]) => (
                        <MenuItem key={roleId} value={roleId}>
                          {roleName}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Password"
                  placeholder="Password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  onChange={(event) => handleInputChange(event, 'password')}
                  error={!validation.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {isPasswordVisible ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  type={isConfirmPasswordVisible ? 'text' : 'password'}
                  onChange={(event) =>
                    handleInputChange(event, 'confirmPassword')
                  }
                  error={!validation.confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {isConfirmPasswordVisible ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Switch
              handleToggle={(event) =>
                handleEditUserForm(event, 'activeIndicator')
              }
              isOn={userObj?.activeIndicator}
              index={Math.random()}
            />
          </Grid>
        </Grid>

        <div className="edit-user-actions">
          <div className="cancel-text" onClick={handleEditCancelChanges}>
            Cancel
          </div>
          <Button
            className="app-button"
            variant="contained"
            color="primary"
            onClick={handleEditSaveChanges}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  )
}

export default EditUser
