import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid, Menu, MenuItem, Typography } from '@mui/material'
import add from '../assets/Add.svg'
import api from '../api'
import ProcessMonitorItem from '../components/ProcessMonitorItem'
import {
  showNotificationMessage,
  updateLoadingState,
  updateProcessData,
} from 'src/actions'
import { NOTIFICATION_TYPES } from 'src/utils/constants'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Popover from '@mui/material/Popover'
import ProcessPopover from 'src/components/ProcessPopover'

const Dashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [dashboardLogs, setDashboardLogs] = useState({})
  const [processList, setProcessList] = useState([])
  const [processMenuElement, setProcessMenuElement] = React.useState(null)
  const isProcessMenuOpen = Boolean(processMenuElement)
  const userId = sessionStorage.getItem('userId')

  const handleProcessMenu = (event) => {
    setProcessMenuElement(event?.currentTarget)
  }

  const fetchProcesses = async () => {
    dispatch(updateLoadingState(true))
    try {
      const response = await api.getAllProcesses(userId ?? '')
      setProcessList(response.data ?? [])
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  const fetchDashboardLogsData = async () => {
    setDashboardLogs({})
    dispatch(updateLoadingState(true))
    try {
      const response = await api.dashboardLogs(
        sessionStorage.getItem('userEmail') ?? ''
      )
      const responseData = response.data
      if (responseData.success) {
        setDashboardLogs(responseData.data ?? {})
      } else {
        setDashboardLogs({})
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Encountered an Error: ${responseData.message}`,
          })
        )
      }
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  const updateDashboardProcessList = async (updatedProcessList) => {
    dispatch(updateLoadingState(true))
    try {
      const response = await api.addOrRemoveProcessFromDashboard(
        userId,
        updatedProcessList
      )

      fetchDashboardLogsData()
      fetchProcesses()
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  useEffect(() => {
    // Fetch API data on component mount
    fetchDashboardLogsData()
    fetchProcesses()
  }, [])

  const dashboardContainerRef = useRef(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = dashboardContainerRef.current

      if (container) {
        const availableHeight =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 10}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const dashboardLogKeys = Object.keys(dashboardLogs)

  const handleProcessClick = async (processData, processName) => {
    // Dispatch the selected processData from a process to store
    dispatch(
      updateProcessData({
        processName,
        processData,
      })
    )
    navigate('/filter-bpm')
  }

  return (
    <>
      <Popover
        id="processMenu"
        anchorEl={processMenuElement}
        open={isProcessMenuOpen}
        onClose={() => handleProcessMenu(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ProcessPopover
          processList={processList}
          handleSubmit={updateDashboardProcessList}
          handleClose={() => handleProcessMenu(null)}
        />
      </Popover>

      <div className="dashboard-main" ref={dashboardContainerRef}>
        <div className="section-heading">
          <div className={'process-monitor-heading'}>Process Monitor</div>
          <Button
            variant="contained"
            color="primary"
            className="app-button"
            onClick={handleProcessMenu}
          >
            <img src={add} alt="add process" className="mr5" />
            <span className="app-button-text">View Process</span>
          </Button>
        </div>
        {/* Map over API data to render blocks dynamically */}

        {dashboardLogKeys.length ? (
          <Grid container spacing={1}>
            {dashboardLogKeys.map((processKey) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                  key={dashboardLogs[processKey].traceId}
                >
                  <ProcessMonitorItem
                    processKey={processKey}
                    processData={dashboardLogs[processKey]}
                    onProcessClick={(data) =>
                      handleProcessClick(data, processKey)
                    }
                    hideIdentifierFilter={true}
                    processList={processList}
                    isDashboard={true}
                    dashboardLogKeys={dashboardLogKeys}
                  />
                </Grid>
              )
            })}
          </Grid>
        ) : (
          <div className="no-data-text">
            No Processes has been assigned yet.
          </div>
        )}
      </div>
    </>
  )
}

export default Dashboard
