import * as React from 'react'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import defaultProfilePicture from '../assets/profile-picture.svg'
import Switch from './switch'
import api from '../api'
import { showNotificationMessage, updateLoadingState } from 'src/actions'
import { NOTIFICATION_TYPES } from 'src/utils/constants'
import { useDispatch } from 'react-redux'

const ProfileCard = ({ userFormHandler, createUser }) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    getAllRoles()
  }, [])

  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    React.useState(false)

  const handleClickShowPassword = () => setIsPasswordVisible((show) => !show)
  const handleClickShowConfirmPassword = () =>
    setIsConfirmPasswordVisible((show) => !show)

  const [roleValuesList, setRoleValuesList] = React.useState([])

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const handleCreateUserForm = (event, type) => {
    userFormHandler(event, type)
  }

  const getAllRoles = async () => {
    dispatch(updateLoadingState(true))
    try {
      const response = await api.getAllRoles()
      if (response.status === 200) {
        setRoleValuesList(response.data)
      } else {
        setRoleValuesList([])
      }
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  const getRoleId = () => {
    return createUser?.roleId ? createUser?.roleId : ''
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} align="center">
          <img
            className="profile-picture"
            src={defaultProfilePicture}
            alt="ProfilePicture"
          />
        </Grid>
        <Grid item xs={12} className="upload-picture-block">
          <Button
            component="label"
            size="medium"
            className="upload-picture"
            disableRipple
          >
            Upload picture
            <VisuallyHiddenInput type="file" />
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} className="active-indicator-block">
          <Switch
            handleToggle={() =>
              handleCreateUserForm(
                !createUser?.activeIndicator,
                'activeIndicator'
              )
            }
            isOn={createUser?.activeIndicator}
            index={Math.random()}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="First Name"
            label="First Name"
            value={createUser?.userFirstName ?? ''}
            onChange={(event) => handleCreateUserForm(event, 'userFirstName')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Last Name"
            label="Last Name"
            value={createUser?.userLastName ?? ''}
            onChange={(event) => handleCreateUserForm(event, 'userLastName')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Department"
            label="Department"
            value={createUser?.userDept ?? ''}
            onChange={(event) => handleCreateUserForm(event, 'userDept')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="mt5">
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Designation"
            label="Designation"
            value={createUser?.userDesignation ?? ''}
            onChange={(event) => handleCreateUserForm(event, 'userDesignation')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Email (Login ID)"
            label="Email (Login ID)"
            value={createUser?.userEmail ?? ''}
            type="email"
            onChange={(event) => handleCreateUserForm(event, 'userEmail')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Phone"
            label="Phone"
            value={createUser?.userMobile ?? ''}
            type="number"
            onChange={(event) => {
              const onlyNums = event.target.value.replace(/[^0-9]/g, '')
              event.target.value = Math.max(0, parseInt(onlyNums))
                .toString()
                .slice(0, 10)
              handleCreateUserForm(event, 'userMobile')
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="mt5">
        <Grid item xs={4}>
          <FormControl>
            <InputLabel>Role</InputLabel>
            <Select
              label="Role"
              placeholder="Role"
              className="select-role"
              value={getRoleId()}
              onChange={(event) => {
                handleCreateUserForm(event, 'roleId')
              }}
              displayEmpty
            >
              <MenuItem value="" disabled>
                <em>Select Role</em>
              </MenuItem>
              {Object.entries(roleValuesList).map(([roleId, roleName]) => (
                <MenuItem key={roleId} value={roleId}>
                  {roleName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <OutlinedInput
            fullWidth
            type={isPasswordVisible ? 'text' : 'password'}
            value={createUser?.password ?? ''}
            onChange={(event) => handleCreateUserForm(event, 'password')}
            label="Password"
            placeholder="Password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={4}>
          <OutlinedInput
            fullWidth
            type={isConfirmPasswordVisible ? 'text' : 'password'}
            value={createUser?.confirmPassword ?? ''}
            onChange={(event) => handleCreateUserForm(event, 'confirmPassword')}
            label="Confirm Password"
            placeholder="Confirm Password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {isConfirmPasswordVisible ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ProfileCard
