import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Zoom,
} from '@mui/material'
import okTick from '../assets/ok-tick.svg'
import plusIcon from '../assets/plus-icon.svg'
import editIcon from '../assets/edit.svg'
import clearIcon from '../assets/clear.svg'
import api from '../api'
import './manageSystemsProcesses.css'
import { showNotificationMessage, updateLoadingState } from 'src/actions'
import { IDENTIFIER_TYPE_LIST, NOTIFICATION_TYPES } from 'src/utils/constants'
import { useDispatch } from 'react-redux'

const ManageSystemsProcesses = () => {
  const dispatch = useDispatch()

  const [showNewSystemForm, setShowNewSystemForm] = useState(false)
  const [showNewProcessForm, setShowNewProcessForm] = useState(false)

  const [systemValuesList, setSystemValuesList] = useState([])
  const [processesList, setProcessesList] = useState([])

  const [systemProcessDetails, setSystemProcessDetails] = useState({
    systemId: '',
    processId: '',
    processName: '',
    filtersList: [],
    isNewProcess: false,
  })

  const [newFilterDetails, setNewFilterDetails] = useState({
    identifierName: '',
    identifierType: '',
    keyIndicator: false,
  })

  const [selectedSystem, setSelectedSystem] = useState({
    systemId: '',
    systemName: '',
    activeIndicator: '',
  })

  const [selectedProcess, setSelectedProcess] = useState({
    processId: '',
    processName: '',
  })

  const [keyFilterIndex, setKeyFilterIndex] = useState(null)

  const renderSystemsList = async () => {
    dispatch(updateLoadingState(true))
    try {
      const response = await api.getSystemMaster()
      if (response.status === 200) {
        setSystemValuesList(response.data)
      } else {
        setSystemValuesList([])
      }
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  const fetchProcessList = async (systemId) => {
    dispatch(updateLoadingState(true))
    try {
      const response = await api.getAllSystemProcessBySystemId(systemId)
      if (response.status === 200) {
        const responseData = response.data
        responseData.forEach((responseItem) => {
          responseItem.isNew = false
        })
        setProcessesList(responseData)
      } else {
        setProcessesList([])
      }
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  const resetDetails = () => {
    setSystemProcessDetails({
      systemId: '',
      processId: '',
      processName: '',
      filtersList: [],
      isNewProcess: false,
    })
    setNewFilterDetails({
      identifierName: '',
      identifierType: '',
      keyIndicator: '',
    })
  }

  const setKeyFilter = () => {
    let index = -1
    index = systemProcessDetails.filtersList?.findIndex((listItem) => listItem.keyIndicator)

    setKeyFilterIndex(index)

  }

  useEffect(() => {
    renderSystemsList()
  }, [])

  useEffect(() => {
    setKeyFilter()
  }, [systemProcessDetails.filtersList])

  const handleSystemInput = (event) => {
    setSelectedSystem({
      ...selectedSystem,
      systemName: event.target.value,
    })
  }

  const handleSystemChange = async (event) => {
    const selectedSystemId = event.target.value

    setSystemProcessDetails({
      ...systemProcessDetails,
      systemId: selectedSystemId,
    })
    await fetchProcessList(selectedSystemId)
  }

  const handleEditSystem = (event, system = null) => {
    event.stopPropagation()
    if (!system) {
      system = {
        systemId: '',
        systemName: '',
        activeIndicator: '',
      }
    }
    setSelectedSystem(system)
    setShowNewSystemForm(true)
  }

  const handleCancelSystemSave = () => {
    setSelectedSystem({
      systemId: '',
      systemName: '',
      activeIndicator: '',
    })
    setShowNewSystemForm(false)
  }

  const handleSystemSave = async () => {
    dispatch(updateLoadingState(true))
    try {
      const response = await api.postSystemMaster(
        selectedSystem.systemId,
        selectedSystem.systemName,
        selectedSystem.activeIndicator
      )
      if (response.status === 201) {
        setSelectedSystem({
          systemId: '',
          systemName: '',
          activeIndicator: '',
        })
        setShowNewSystemForm(false)
        await renderSystemsList()
        setSystemProcessDetails({
          ...systemProcessDetails,
          systemId: response.data.systemId,
        })
      } else {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.ERROR,
            message: `Encountered an Error while saving System`,
          })
        )
      }
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error}`,
        })
      )
    }
  }

  const handleProcessChange = (event) => {
    const selectedProcessId = event.target.value
    const selectedProcess = processesList.find(
      (process) => process.processId === selectedProcessId
    )

    const filters = selectedProcess.systemProcessIdentifiers.map(
      (processIdentifier) => {
        return {
          ...processIdentifier,
          isEdit: false,
        }
      }
    )

    setSystemProcessDetails({
      ...systemProcessDetails,
      processName: selectedProcess.processName,
      processId: selectedProcess.processId,
      filtersList: [...filters],
      isNewProcess: selectedProcess.isNew,
    })
  }

  const handleEditProcess = (event, process = null) => {
    event.stopPropagation()
    if (!process) {
      process = {
        processId: '',
        processName: '',
        isNew: false,
      }
    }
    setSelectedProcess(process)
    setShowNewProcessForm(true)
  }

  const handleProcessInput = (event) => {
    setSelectedProcess({
      ...selectedProcess,
      processName: event.target.value,
    })
  }

  const handleCancelProcessSave = () => {
    setSelectedProcess({
      processId: '',
      processName: '',
      isNew: false,
    })
    setShowNewProcessForm(false)
  }

  const handleProcessSave = () => {
    const existingProcessIndex = processesList.findIndex(
      (process) => process.processId === selectedProcess.processId
    )
    const updatedProcessList = [...processesList]

    if (existingProcessIndex !== -1) {
      updatedProcessList[existingProcessIndex] = {
        ...updatedProcessList[existingProcessIndex],
        processName: selectedProcess.processName,
      }
      setSystemProcessDetails({
        ...systemProcessDetails,
        processName: selectedProcess.processName,
        processId: selectedProcess.processId,
        filtersList: [],
        isNewProcess: selectedProcess.isNew,
      })
    } else {
      const newProcess = {
        processId: processesList.length + 1,
        processName: selectedProcess.processName,
        systemProcessIdentifiers: [],
      }
      updatedProcessList.push(newProcess)
      setSystemProcessDetails({
        ...systemProcessDetails,
        processName: newProcess.processName,
        processId: newProcess.processId,
        filtersList: [],
        isNewProcess: true,
      })
    }

    setProcessesList([...updatedProcessList])
    setSelectedProcess({
      processId: '',
      processName: '',
      isNew: false,
    })
    setShowNewProcessForm(false)
  }

  const handleFilterChange = (event, index = null) => {
    const { name, value, checked } = event.target
    if (index !== null) {
      const updatedFilterList = [...systemProcessDetails.filtersList]
      let filterItem = updatedFilterList[index]
      filterItem = {
        ...filterItem,
        [name]: value,
      }

      updatedFilterList[index] = filterItem

      setSystemProcessDetails({
        ...systemProcessDetails,
        filtersList: [...updatedFilterList],
      })
    } else {
      if (name === 'keyIndicator') {
        setNewFilterDetails({
          ...newFilterDetails,
          [name]: checked,
        })
      } else {
        setNewFilterDetails({
          ...newFilterDetails,
          [name]: value,
        })
      }
    }
  }

  const saveFilter = () => {
    const newFilter = {
      ...newFilterDetails,
      activeIndicator: true,
    }

    const updatedFilterList = [...systemProcessDetails.filtersList]
    updatedFilterList.push(newFilter)

    if (newFilter.keyIndicator) {
      setKeyFilterIndex(updatedFilterList.length - 1)
    }

    setSystemProcessDetails({
      ...systemProcessDetails,
      filtersList: [...updatedFilterList],
    })

    setNewFilterDetails({
      identifierName: '',
      identifierType: '',
      keyIndicator: false,
    })
  }

  const validateKeyIndicator = (identifierList) => {
    if (identifierList.length) {
      let isKeyIndicatorSelected = false
      identifierList.forEach((listItem) => {
        if (listItem.keyIndicator) {
          isKeyIndicatorSelected = true
        }
      })

      return isKeyIndicatorSelected
    }
    return true
  }

  const saveSystemProcessBtnClick = async () => {
    const processIdentifiersList = systemProcessDetails.filtersList.map(
      (filterItem, index) => {
        return {
          ...filterItem,
          serialNumber: index + 1,
          keyIndicator: keyFilterIndex === index,
        }
      }
    )

    if (!validateKeyIndicator(processIdentifiersList)) {
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.WARNING,
          message: `Please select a key Identifier`,
        })
      )
      return
    }

    const requestBody = {
      systemId: systemProcessDetails.systemId,
      systemProcessAndIdentifierList: [
        {
          processName: systemProcessDetails.processName,
          processId: systemProcessDetails.isNewProcess
            ? ''
            : systemProcessDetails.processId,
          activeIndicator: true,
          systemProcessIdentifiers: [...processIdentifiersList],
        },
      ],
    }

    dispatch(updateLoadingState(true))
    try {
      const response = await api.createOrUpdateSystemProcess(requestBody)
      if (response.status === 200) {
        resetDetails()
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.SUCCESS,
            message: `System Process created successfully`,
          })
        )
      } else {
        dispatch(
          showNotificationMessage({
            type: NOTIFICATION_TYPES.SUCCESS,
            message: `Encountered an error while updating the system process`,
          })
        )
      }
      dispatch(updateLoadingState(false))
    } catch (error) {
      dispatch(updateLoadingState(false))
      dispatch(
        showNotificationMessage({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Encountered an Error: ${error.message}`,
        })
      )
    }
  }

  const toggleFilterEdit = (index) => {
    const updatedFilterList = [...systemProcessDetails.filtersList]
    updatedFilterList[index].isEdit = !updatedFilterList[index].isEdit

    setSystemProcessDetails({
      ...systemProcessDetails,
      filtersList: [...updatedFilterList],
    })
  }

  const removeFilter = (index) => {
    const updatedFilterList = [...systemProcessDetails.filtersList]
    updatedFilterList.splice(index, 1)

    setSystemProcessDetails({
      ...systemProcessDetails,
      filtersList: [...updatedFilterList],
    })
  }

  const isFilterSaveDisabled = (filterDetails) => {
    return !filterDetails.identifierName || !filterDetails.identifierType
  }

  const isSystemSaveDisabled = () => {
    return !selectedSystem.systemName
  }

  const isProcessSaveDisabled = () => {
    return !selectedProcess.processName
  }

  const isKeyFilter = (index) => {
    return keyFilterIndex !== null && keyFilterIndex === index
  }

  const handleSetKeyFilter = (index) => {
    if (index !== keyFilterIndex) {
      setKeyFilterIndex(index)
    } else {
      setKeyFilterIndex(null)
    }
  }

  return (
    <div className="manage-systems-container">
      <div className="manage-systems-heading">Manage Systems & Processes</div>
      <div className="manage-systems-page-container">
        <div className="block-container">
          <div className="top-container">
            {showNewSystemForm ? (
              <div className="new-system-form-container form-container">
                <div className="form-heading">New System Name</div>

                <TextField
                  className="define-filters-inputs"
                  variant="outlined"
                  placeholder="Enter System Name"
                  value={selectedSystem.systemName}
                  onChange={handleSystemInput}
                />

                <Tooltip
                  TransitionComponent={Zoom}
                  placement="bottom"
                  title={'Save System'}
                  arrow
                >
                  <IconButton
                    disabled={isSystemSaveDisabled()}
                    onClick={handleSystemSave}
                  >
                    <img src={okTick} alt="okTick" className={'action-icon'} />
                  </IconButton>
                </Tooltip>

                <div className="cancel-text" onClick={handleCancelSystemSave}>
                  Cancel
                </div>
              </div>
            ) : (
              <div className="system-form-container form-container">
                <div className="form-heading">System</div>

                <div className="select-system-form-container">
                  <FormControl>
                    <Select
                      className="select-item"
                      value={systemProcessDetails.systemId}
                      displayEmpty
                      onChange={handleSystemChange}
                    >
                      <MenuItem value="" disabled>
                        <em>Select System</em>
                      </MenuItem>
                      {systemValuesList.map((systemObject) => (
                        <MenuItem
                          key={systemObject.systemId}
                          value={systemObject.systemId}
                        >
                          <div className={'menu-list-item'}>
                            <span>{systemObject.systemName}</span>
                            <div className="edit-actions-container">
                              <img
                                src={editIcon}
                                alt="editIcon"
                                onClick={(event) => {
                                  handleEditSystem(event, systemObject)
                                }}
                              />
                            </div>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="system-separator"></div>

                <div className="">
                  <Button
                    variant="contained"
                    color="primary"
                    className="add_system_btn"
                    onClick={handleEditSystem}
                  >
                    + Add System
                  </Button>
                </div>
              </div>
            )}

            <div className="separator"></div>

            {showNewProcessForm ? (
              <div className="new-process-form-container form-container">
                <div className="form-heading">New Process Name</div>

                <TextField
                  className="define-filters-inputs"
                  variant="outlined"
                  placeholder="Enter Process Name"
                  value={selectedProcess.processName}
                  onChange={handleProcessInput}
                />

                <Tooltip
                  TransitionComponent={Zoom}
                  placement="bottom"
                  title={'Save Process'}
                  arrow
                >
                  <IconButton
                    disabled={isProcessSaveDisabled()}
                    onClick={handleProcessSave}
                  >
                    <img src={okTick} alt="okTick" className={'action-icon'} />
                  </IconButton>
                </Tooltip>

                <div className="cancel-text" onClick={handleCancelProcessSave}>
                  Cancel
                </div>
              </div>
            ) : (
              <div className="form-container">
                <div className="form-heading">Process</div>

                <div className="select-system-form-container">
                  <FormControl>
                    <Select
                      className="select-item"
                      value={systemProcessDetails.processId}
                      displayEmpty
                      onChange={handleProcessChange}
                    >
                      <MenuItem value="" disabled>
                        <em>Select Process</em>
                      </MenuItem>
                      {processesList.map((processObj) => (
                        <MenuItem
                          key={processObj.processId}
                          value={processObj.processId}
                        >
                          <div className={'menu-list-item'}>
                            <span>{processObj.processName}</span>
                            <div className="edit-actions-container">
                              <img
                                src={editIcon}
                                alt="editIcon"
                                onClick={(event) => {
                                  handleEditProcess(event, processObj)
                                }}
                              />
                            </div>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="process-separator"></div>

                <div className="">
                  <Button
                    variant="contained"
                    color="primary"
                    className="add_process_btn"
                    onClick={handleEditProcess}
                  >
                    + Add Process
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className="define-filters-container">
            <div className="table-heading">Define Filters</div>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Key</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {systemProcessDetails.filtersList.map(
                    (systemProcessFilter, index) => (
                      <TableRow key={systemProcessFilter?.identifierId}>
                        <TableCell>
                          <Checkbox
                            checked={isKeyFilter(index)}
                            onChange={() => handleSetKeyFilter(index)}
                          />
                        </TableCell>
                        {systemProcessFilter.isEdit ? (
                          <>
                            <TableCell>
                              <TextField
                                className="define-filters-inputs"
                                variant="outlined"
                                placeholder="Enter Name"
                                id="identifierName"
                                name="identifierName"
                                value={systemProcessFilter.identifierName}
                                onChange={(event) =>
                                  handleFilterChange(event, index)
                                }
                                size="medium"
                              />
                            </TableCell>
                            <TableCell>
                              <Select
                                className="define-filters-inputs"
                                variant="outlined"
                                placeholder="Enter Type"
                                id="identifierType"
                                name="identifierType"
                                value={systemProcessFilter?.identifierType}
                                onChange={(event) =>
                                  handleFilterChange(event, index)
                                }
                                fullWidth
                                displayEmpty
                                size="medium"
                              >
                                <MenuItem value="" disabled>
                                  Select Identifier Type
                                </MenuItem>
                                {IDENTIFIER_TYPE_LIST.map((identifierType) => (
                                  <MenuItem
                                    key={identifierType.type}
                                    value={identifierType.type}
                                  >
                                    {identifierType.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                            <TableCell>
                              <div className="add-filter-block">
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  placement="bottom"
                                  title={'Save Filter'}
                                  arrow
                                >
                                  <IconButton
                                    disabled={isFilterSaveDisabled(
                                      systemProcessFilter
                                    )}
                                    onClick={() => toggleFilterEdit(index)}
                                  >
                                    <img src={okTick} alt="saveIcon" />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>
                              {systemProcessFilter?.identifierName}
                            </TableCell>
                            <TableCell>
                              {systemProcessFilter?.identifierType}
                            </TableCell>
                            <TableCell>
                              <div className="add-filter-block">
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  placement="bottom"
                                  title={'Edit Filter'}
                                  arrow
                                >
                                  <img
                                    src={editIcon}
                                    alt="editIcon"
                                    onClick={() => toggleFilterEdit(index)}
                                  />
                                </Tooltip>
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  placement="bottom"
                                  title={'Remove Filter'}
                                  arrow
                                >
                                  <img
                                    src={clearIcon}
                                    alt="clearIcon"
                                    onClick={() => removeFilter(index)}
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    )
                  )}
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        id="keyIndicator"
                        name="keyIndicator"
                        checked={newFilterDetails.keyIndicator}
                        onChange={(event) => handleFilterChange(event, null)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        className="define-filters-inputs"
                        variant="outlined"
                        placeholder="Enter Name"
                        id="identifierName"
                        name="identifierName"
                        value={newFilterDetails.identifierName}
                        onChange={handleFilterChange}
                        size="medium"
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        className="define-filters-inputs"
                        variant="outlined"
                        placeholder="Enter Type"
                        id="identifierType"
                        name="identifierType"
                        value={newFilterDetails.identifierType}
                        onChange={(event) => handleFilterChange(event)}
                        fullWidth
                        displayEmpty
                        size="medium"
                      >
                        <MenuItem value="" disabled>
                          <em>Select Identifier Type</em>
                        </MenuItem>
                        {IDENTIFIER_TYPE_LIST.map((identifierType) => {
                          return (
                            <MenuItem
                              key={identifierType.type}
                              value={identifierType.type}
                            >
                              {identifierType.label}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <div className="add-filter-block">
                        <Tooltip
                          TransitionComponent={Zoom}
                          placement="bottom"
                          title={'Add Filter'}
                          arrow
                        >
                          <IconButton
                            disabled={isFilterSaveDisabled(newFilterDetails)}
                            onClick={saveFilter}
                          >
                            <img src={plusIcon} alt="plusIcon" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>

            <div className="save_filter_btn_block">
              <Button
                variant="contained"
                color="primary"
                className="save-btn"
                onClick={saveSystemProcessBtnClick}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageSystemsProcesses
